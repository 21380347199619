import styled from "styled-components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputWithLabel, StyledButton } from "../../ui/styled-inputs";
import { useQueryClient } from "@tanstack/react-query";
import { useLogin } from "../../api/querys/auth-querys";

const LoginSpace = styled.form`
  width: 60%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  padding: 1rem 1rem;

  @media only screen and (max-width: 900px) {
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    .spacer {
      display: none;
    }
  }

  .link-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  button,
  div,
  h1 {
    width: auto;
    height: 3rem;
  }
`;

export function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const queryClient = useQueryClient();
  const login = useLogin(queryClient);

  return (
    <LoginSpace
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoggingIn(true);
        login.mutateAsync({ email, password }).finally(() => {
          setIsLoggingIn(false);
        });
      }}
    >
      <h1>Login</h1>
      <div className="spacer" />
      <InputWithLabel
        type="email"
        label="E-Mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <InputWithLabel
        type="password"
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <StyledButton type="submit">
        {isLoggingIn ? "Lade.." : "Login"}
      </StyledButton>
      <div className="link-container">
        <Link to="/resetPassword">Passwort vergessen</Link>
        <Link to="/register">Registrieren</Link>
      </div>
    </LoginSpace>
  );
}
