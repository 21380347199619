import React, { useState } from "react";
import { PageWrapper } from "../../components/ui/page-wrapper";
import { Outlet } from "react-router-dom";
import UserListItemComponent from "../../components/domain/user/admin/user-list-item-component";
import { BreadCrumbsProps } from "../../components/ui/layout/bread-crumbs";
import { BaseType } from "../../types/base-type";
import { LoadingSpinner } from "../../components/ui/loading-spinner";
import { ListWrapper } from "../../components/ui/list-wrapper";
import { useGetUsersInt } from "../../components/api/querys/user-querys";

function ManageUsersRoute(props: BaseType) {
  const { children } = props;
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  const { data, isLoading, isError } = useGetUsersInt({
    page,
    search: searchText,
  });

  const navPathLinks: BreadCrumbsProps[] = [{ text: "Nutzer verwalten" }];

  return (
    <PageWrapper
      links={navPathLinks}
      pageTitle="Nutzer verwalten"
      setSearchText={setSearchText}
      searchText={searchText}
      hasCreateButton={true}
      pagination={{
        page,
        setPage,
        totalPages: data?.data.totalPages,
        totalElements: data?.data.totalElements,
      }}
    >
      <ListWrapper>
        <UserListItemComponent />
        {isError && "Ein Fehler ist aufgetreten.."}
        {isLoading && <LoadingSpinner />}
        {data?.data?.elements?.map((user, i) => (
          <UserListItemComponent key={i} user={user} />
        ))}
      </ListWrapper>
      {children ? children : <Outlet />}
    </PageWrapper>
  );
}

export default ManageUsersRoute;
