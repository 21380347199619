import React, { useState } from "react";
import styled from "styled-components";
import { eventIntController } from "../../../api/api-functions";
import Paginierung from "../../../ui/paginierung";
import { StyledButton } from "../../../ui/styled-inputs";
import { BaseType } from "../../../../types/base-type";
import { LoadingSpinner } from "../../../ui/loading-spinner";
import { alertLoading } from "../../../ui/alert";
import fileDownload from "js-file-download";
import EditRegistrationsListItemComponent from "./edit-registrations-list-item-component";
import { useIntGetEventById } from "../../../api/querys/event-querys";
import { useGetEventRegistrationsInt } from "../../../api/querys/event-registration-querys";

interface EditEventRegistrationsTabProps extends BaseType {
  eventId: string | undefined;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  gap: 1rem;
`;

function EditEventRegistrationsTab(props: EditEventRegistrationsTabProps) {
  const { eventId } = props;

  const { data: event } = useIntGetEventById(eventId!, false);

  const [page, setPage] = useState(0);

  const { data, isLoading, isError } = useGetEventRegistrationsInt(
    eventId,
    page
  );

  if (isError) return <>Ein Fehler ist aufgetreten</>;
  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <StyledContent>
        <EditRegistrationsListItemComponent />
        {data?.data.elements?.map((registration, key) => {
          return (
            <EditRegistrationsListItemComponent
              registration={registration}
              key={key}
            />
          );
        })}
      </StyledContent>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "1rem",
        }}
      >
        <Paginierung
          page={page}
          setPage={setPage}
          totalPages={data?.data.totalPages}
          totalElements={data?.data.totalElements}
        ></Paginierung>
        <StyledButton
          style={{ display: !eventId ? "none" : "", width: "50%" }}
          type="button"
          onClick={() => {
            alertLoading(
              eventIntController
                .downloadRegistrationsEvent(eventId!, { responseType: "blob" })
                .then((res) =>
                  fileDownload(
                    res.data as unknown as Blob,
                    (event?.data.title
                      ? event?.data.title
                      : "Unbenanntes Event") +
                      new Date().toISOString() +
                      ".xlsx"
                  )
                )
            );
          }}
        >
          Download Registrierungen
        </StyledButton>
      </div>
    </>
  );
}

export default EditEventRegistrationsTab;
