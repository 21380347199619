import { EventRsp } from "../components/api/generated";
import moment from "moment";

const ics = require("ics");

export const createIcalFromEvent = (event: EventRsp) => {
  if (!event.fromDate || !event.toDate)
    throw Error("from or to Date is undefined");

  const fromDate = moment(new Date(event.fromDate))
    .format("YYYY-M-D-H-m")
    .split("-")
    .map((i) => {
      return Number(i);
    });
  const toDate = moment(new Date(event.toDate))
    .format("YYYY-M-D-H-m")
    .split("-")
    .map((i) => {
      return Number(i);
    });

  let hostname = window.location.host;
  hostname = hostname.startsWith("localhost")
    ? "http://" + hostname
    : "https://" + hostname;

  return ics.createEvent({
    created: undefined,
    title: event.title,
    location: event.location,
    description: event.description,
    start: fromDate,
    end: toDate,
    url: hostname + "/events/" + event.id,
  });
};
