import React from "react";
import styled from "styled-components";
import { AccountIntRsp } from "../../../api/generated";
import { BaseType } from "../../../../types/base-type";
import { BsCheckCircle } from "react-icons/bs";
import { StyledButton } from "../../../ui/styled-inputs";
import { useQueryClient } from "@tanstack/react-query";
import { alertSave } from "../../../ui/alert";
import { useRegisterForEventInt } from "../../../api/querys/event-registration-querys";

interface AddRegistrationsListItemComponentProps extends BaseType {
  user?: AccountIntRsp;
  eventId: string;
}

function AddRegistrationsListItemStruct(
  props: AddRegistrationsListItemComponentProps
) {
  const { className, user, eventId } = props;

  const queryClient = useQueryClient();

  const updateMutation = useRegisterForEventInt(queryClient);

  return (
    <div className={className}>
      <div>{user ? user.firstName + " " + user.lastName : <h4>Name</h4>}</div>
      <div>
        {user ? (
          <>
            <StyledButton
              onClick={() => {
                alertSave(
                  updateMutation.mutateAsync({
                    accountId: user.id,
                    eventId,
                  })
                );
              }}
            >
              <BsCheckCircle color="green" />
            </StyledButton>
          </>
        ) : (
          <h4>Anmelden</h4>
        )}
      </div>
    </div>
  );
}

const AddRegistrationsListItemComponent = styled(
  AddRegistrationsListItemStruct
)`
  background-color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  justify-content: space-between;
  min-height: 2rem;
  width: 100%;
  border-radius: ${(props) => props.theme.mediumBorderRadius};

  h4 {
    margin: 0;
  }

  div:nth-child(1) {
    width: 10rem;
  }

  div:nth-child(2) {
    width: 5rem;
    display: flex;
    justify-content: center;
  }
`;

export default AddRegistrationsListItemComponent;
