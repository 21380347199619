import React from "react";

import styled from "styled-components";
import Header from "../../components/ui/layout/header";
import Footer from "../../components/ui/layout/footer";

const NotFoundStyle = styled.div`
  display: grid;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: 100px 1fr 50px;
`;

const Wrapper = styled.div`
  background: #dfe9e3;
  grid-area: main;
`;

const NotFoundRoute = () => {
  return (
    <NotFoundStyle>
      <Header />
      <Wrapper>
        <h1>404 not found</h1>
      </Wrapper>
      <Footer />
    </NotFoundStyle>
  );
};
export default NotFoundRoute;
