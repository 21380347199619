import React, { useEffect } from "react";
import { PageWrapper } from "../../components/ui/page-wrapper";
import { BreadCrumbsProps } from "../../components/ui/layout/bread-crumbs";
import { Card } from "../../components/ui/card";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGetMe } from "../../components/api/querys/auth-querys";
import { UpdateAccountMeReq } from "../../components/api/generated";
import { emptyStringToUndefined } from "../../utils/empty-string-to-undefined";
import { accountController } from "../../components/api/api-functions";
import { alertSave } from "../../components/ui/alert";
import { ProfileFormComponent } from "../../components/domain/user/profile-form";
import {
  useGetNotificationSettings,
  useUpdateNotificationSetting,
} from "../../components/api/querys/notification-querys";
import { NotificationSettingsFormComponent } from "../../components/domain/user/notification-settings-form-component";

const navPathLinks: BreadCrumbsProps[] = [];

export default function ProfileRoute() {
  const { register, handleSubmit, setValue, formState } = useForm();

  const queryClient = useQueryClient();

  const { data } = useGetMe();

  const updateMutation = useMutation({
    mutationFn: async (data: any) => {
      const {
        firstName,
        lastName,
        phoneNumber,
        password,
        passwordRepeat,
        city,
        postalCode,
        houseNumber,
        street,
      } = data;

      if (password && password !== passwordRepeat) {
        throw new Error("Passwörter stimmen nicht überein");
      }

      let updateRequest: UpdateAccountMeReq = {
        firstName: emptyStringToUndefined(firstName),
        lastName: emptyStringToUndefined(lastName),
        phoneNumber: emptyStringToUndefined(phoneNumber),
        city: emptyStringToUndefined(city),
        postalCode: emptyStringToUndefined(postalCode),
        houseNumber: emptyStringToUndefined(houseNumber),
        street: emptyStringToUndefined(street),
        password: emptyStringToUndefined(password),
      };
      return await accountController.updatePrincipal(updateRequest);
    },
    onSuccess: async () => {
      setValue("password", "");
      setValue("passwordRepeat", "");
      await queryClient.invalidateQueries(["me"]);
      await queryClient.invalidateQueries(["users"]);
    },
  });

  const { data: notifications } = useGetNotificationSettings();

  const { mutate: updateNotificationSettings } =
    useUpdateNotificationSetting(queryClient);

  useEffect(() => {
    if (data != null) {
      const { firstName, lastName, phoneNumber, address, email, ljvNumber } =
        data;
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("phoneNumber", phoneNumber);
      setValue("email", email);
      setValue("ljvNumber", ljvNumber);
      setValue("city", address?.city);
      setValue("postalCode", address?.postalCode);
      setValue("houseNumber", address?.houseNumber);
      setValue("street", address?.street);
    }
  }, [data]);

  return (
    <PageWrapper pageTitle="Profil" links={navPathLinks}>
      <Card>
        <ProfileFormComponent
          type="PROFILE"
          register={register}
          handleSubmit={handleSubmit}
          onSubmit={(data) => {
            alertSave(updateMutation.mutateAsync(data));
          }}
          submitButtonText="Speichern"
          formState={formState}
        />
        <NotificationSettingsFormComponent
          notifications={notifications?.notifications || []}
          updateNotificationSettings={(setting, enabled) => {
            updateNotificationSettings({ setting, enabled });
          }}
        />
      </Card>
    </PageWrapper>
  );
}
