import { NotificationRsp } from "../../api/generated";
import styled from "styled-components";
import ToggleSwitch from "../../ui/styled-inputs";

const NotificationSettingsFrom = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  padding-top: 2rem;

  section {
    width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .notification-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .notification-description {
    width: 100%;
    display: flex;
    text-align: left;
    color: ${(props) => props.theme.secondaryDark};
  }

  h1 {
    width: 40rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
`;

export function NotificationSettingsFormComponent({
  notifications,
  updateNotificationSettings,
}: {
  notifications: NotificationRsp[];
  updateNotificationSettings: (setting: string, enabled: boolean) => any;
}) {
  return (
    <NotificationSettingsFrom>
      <h1>Benachrichtigungs Einstellungen:</h1>
      {notifications.map((n, key) => {
        return (
          <section key={key}>
            <div className="notification-top">
              {n.label}
              <ToggleSwitch
                checked={n.enabled}
                setChecked={(checked: boolean) => {
                  updateNotificationSettings(n.setting!, checked);
                }}
              />
            </div>
            <div className="notification-description">{n.description}</div>
          </section>
        );
      })}
    </NotificationSettingsFrom>
  );
}
