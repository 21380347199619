import React from "react";
import styled from "styled-components";
import { AccountIntRsp } from "../../../api/generated";
import { BaseType } from "../../../../types/base-type";
import { GrLogin, GrUser, GrUserPolice } from "react-icons/gr";
import { useQueryClient } from "@tanstack/react-query";
import { BsCheckCircle, BsPencilFill, BsXCircle } from "react-icons/bs";
import { useImpersonateInt } from "../../../api/querys/auth-querys";
import { useNavigate } from "react-router-dom";

interface UserListItemComponentProps extends BaseType {
  user?: AccountIntRsp;
}

function UserListItemComponentStruct(props: UserListItemComponentProps) {
  const { className, user } = props;
  const queryClient = useQueryClient();
  const impersonate = useImpersonateInt(queryClient);

  const navigate = useNavigate();

  return (
    <div className={className}>
      <div>{user ? user.firstName : <h4>Vorname</h4>} </div>
      <div>{user ? user.lastName : <h4>Nachname</h4>} </div>
      <div>{user ? user.email : <h4>Email</h4>} </div>
      <div>{user ? user.ljvNumber : <h4>LJV Nummer</h4>} </div>
      <div>
        {user ? (
          user.approved ? (
            <BsCheckCircle color="green" />
          ) : (
            <BsXCircle color="red" />
          )
        ) : (
          <h4>Aktiviert</h4>
        )}{" "}
      </div>
      <div>
        {user ? (
          user.role === "ADMIN" ? (
            <GrUserPolice />
          ) : (
            <GrUser />
          )
        ) : (
          <h4>Rolle</h4>
        )}{" "}
      </div>
      <div className="buttonSpace">
        {user && (
          <>
            <button
              className="impersonateButton"
              onClick={() => {
                if (user?.email) impersonate.mutate(user.email);
              }}
            >
              <GrLogin />
            </button>

            <button
              className="editButton"
              onClick={() => {
                if (user?.id) navigate(user.id);
              }}
            >
              <BsPencilFill />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

const UserListItemComponent = styled(UserListItemComponentStruct)`
  background-color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2rem;
  width: 100%;
  padding: 0.25rem 1rem;
  border-radius: ${(props) => props.theme.mediumBorderRadius};

  h4 {
    margin: 0;
  }

  div:nth-child(1) {
    width: 9rem;
  }
  div:nth-child(2) {
    width: 9rem;
  }
  div:nth-child(3) {
    width: 12rem;
  }
  div:nth-child(4) {
    width: 7rem;
  }
  div:nth-child(5) {
    width: 5rem;
    display: flex;
    justify-content: center;
  }
  div:nth-child(6) {
    width: 3rem;
    display: flex;
    justify-content: center;
  }

  .buttonSpace {
    justify-self: end;
    display: flex;
    height: 2rem;
    width: 4rem;
    gap: 0.5rem;
  }

  .editButton,
  .impersonateButton {
    height: 2rem;
    width: 2rem;
    text-decoration: none;
    color: ${(props) => props.theme.black};
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
  }
`;

export default UserListItemComponent;
