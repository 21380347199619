import styled from "styled-components";
import { Link } from "react-router-dom";
import React from "react";
import { BaseType } from "../../../types/base-type";

export interface BreadCrumbsProps {
  text: string;
  link?: string;
}

interface BreadCrumbProps extends BaseType {
  links?: BreadCrumbsProps[];
}

function Crumb(props: BreadCrumbProps) {
  const { links, className } = props;
  return (
    <section className={className}>
      {links?.map(({ link, text }, key) => {
        if (link == null) {
          return <span key={key}>{text}</span>;
        }
        return (
          <Link key={key} to={link}>
            {text}
          </Link>
        );
      })}
    </section>
  );
}

export const BreadCrumbs = styled(Crumb)`
  a,
  a:visited {
    color: ${(props) => props.theme.primary};
    text-decoration: none;
    transition: all 0.1s;
  }

  a:hover {
    color: ${(props) => props.theme.primaryDarker2};
  }

  a:after {
    content: " » ";
  }

  color: ${(props) => props.theme.primary};
  margin: 0 0 1rem 0;
`;
