import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { notificationController } from "../api-functions";
import { alertErrorDefault, alertSuccess } from "../../ui/alert";

export const useGetNotificationSettings = () =>
  useQuery({
    queryKey: ["notificationSettings"],
    queryFn: async () => {
      return (await notificationController.getNotificationSettings()).data;
    },
    refetchOnWindowFocus: true,
    retry: 0,
    staleTime: 500,
  });

export const useUpdateNotificationSetting = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async ({
      setting,
      enabled,
    }: {
      setting: string;
      enabled: boolean;
    }) => {
      await notificationController.updateNotificationSettings({
        setting,
        enabled,
      });
    },
    onSuccess: async () => {
      alertSuccess(`Gespeichert!`);
      await queryClient.invalidateQueries(["notificationSettings"]);
    },
    onError: () => {
      alertErrorDefault();
    },
  });
