import { PageWrapper } from "../../components/ui/page-wrapper";
import { Card } from "../../components/ui/card";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateUserInt,
  useDeleteUserInt,
  useGetUserByIdInt,
  useResetPasswordForUserInt,
  useUpdateUserInt,
} from "../../components/api/querys/user-querys";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import {
  AccountIntRsp,
  CreateAccountIntReq,
  UpdateAccountIntReq,
} from "../../components/api/generated";
import { emptyStringToUndefined } from "../../utils/empty-string-to-undefined";
import {
  alertDelete,
  alertLoading,
  alertSave,
  alertSuccess,
} from "../../components/ui/alert";
import { ProfileFormComponent } from "../../components/domain/user/profile-form";
import { StyledButton } from "../../components/ui/styled-inputs";

export function EditUsersRoute() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { register, handleSubmit, setValue, formState } = useForm();

  const queryClient = useQueryClient();

  const { data } = useGetUserByIdInt(id, id !== "new");

  const updateMutation = useUpdateUserInt(queryClient);
  const createMutation = useCreateUserInt(queryClient, navigate);
  const deleteMutation = useDeleteUserInt(queryClient, id!, navigate);
  const resetPasswordMutation = useResetPasswordForUserInt(queryClient, id!);

  const pageTitel =
    id === "new"
      ? "Neuen Nutzer anlegen"
      : (data?.data.firstName || "") + (data?.data.lastName || "");

  function createUpdateReq(data: any) {
    const {
      firstName,
      lastName,
      ljvNumber,
      email,
      role,
      phoneNumber,
      approved,
      emailVerified,
      city,
      postalCode,
      houseNumber,
      street,
      password,
      passwordRepeat,
    } = data;

    let cleanPasswordRepeat = emptyStringToUndefined(passwordRepeat);

    let updateRequest: UpdateAccountIntReq = {
      firstName,
      lastName,
      ljvNumber,
      email,
      role,
      phoneNumber: emptyStringToUndefined(phoneNumber),
      approved: approved || false,
      emailVerified: emailVerified || false,
      city: emptyStringToUndefined(city),
      postalCode: emptyStringToUndefined(postalCode),
      houseNumber: emptyStringToUndefined(houseNumber),
      street: emptyStringToUndefined(street),
      password: emptyStringToUndefined(password),
    };
    return {
      req: updateRequest,
      userId: id,
      passwordRepeat: cleanPasswordRepeat,
    };
  }

  function createCreateReq(data: any) {
    const {
      firstName,
      lastName,
      ljvNumber,
      email,
      role,
      phoneNumber,
      approved,
      emailVerified,
      password,
      passwordRepeat,
      city,
      postalCode,
      houseNumber,
      street,
    } = data;

    let cleanPasswordRepeat = emptyStringToUndefined(passwordRepeat);

    let createRequest: CreateAccountIntReq = {
      approved: approved || false,
      emailVerified: emailVerified || false,
      enabled: true,
      password: emptyStringToUndefined(password)!,
      firstName,
      lastName,
      ljvNumber,
      email,
      role,
      phoneNumber: emptyStringToUndefined(phoneNumber),
      city,
      postalCode,
      houseNumber,
      street,
    };

    return { req: createRequest, passwordRepeat: cleanPasswordRepeat };
  }

  const setForm = (user: AccountIntRsp) => {
    setValue("firstName", user.firstName);
    setValue("lastName", user.lastName);
    setValue("ljvNumber", user.ljvNumber);
    setValue("email", user.email);
    setValue("role", user.role);
    setValue("phoneNumber", user.phoneNumber);
    setValue("approved", user.approved);
    setValue("emailVerified", user.emailVerified);
    setValue("city", user.address?.city);
    setValue("postalCode", user.address?.postalCode);
    setValue("houseNumber", user.address?.houseNumber);
    setValue("street", user.address?.street);
    setValue("password", "");
    setValue("passwordRepeat", "");
  };

  useEffect(() => {
    if (id === "new") {
      setForm({});
    } else if (data?.data != null) {
      setForm(data.data);
    }
  }, [data, id]);

  return (
    <PageWrapper
      links={[
        { text: "Nutzer verwalten", link: "/manage-users" },
        { text: pageTitel },
      ]}
      pageTitle={pageTitel}
    >
      <Card>
        <ProfileFormComponent
          type={id === "new" ? "CREATE_USER" : "EDIT_USER"}
          register={register}
          handleSubmit={handleSubmit}
          formState={formState}
          onSubmit={(data) => {
            alertSave(
              id === "new"
                ? createMutation.mutateAsync(createCreateReq(data))
                : updateMutation.mutateAsync(createUpdateReq(data))
            );
          }}
          buttonSpace={
            id !== "new" && (
              <>
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    alertLoading(resetPasswordMutation.mutateAsync()).then(() =>
                      alertSuccess(
                        "Dem Nutzer wird nun eine Mail mit neuem Passwort gesendet."
                      )
                    );
                  }}
                >
                  Password zurücksetzen
                </StyledButton>
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    alertDelete(deleteMutation.mutateAsync());
                  }}
                >
                  Löschen
                </StyledButton>
              </>
            )
          }
          submitButtonText="Speichern"
        />
      </Card>
    </PageWrapper>
  );
}
