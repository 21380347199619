import React from "react";
import styled from "styled-components";
import { EventIntRsp } from "../../../api/generated";
import { BaseType } from "../../../../types/base-type";
import { BsCheckCircle, BsPencilFill, BsXCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { BiDuplicate } from "react-icons/bi";
import { useIntDuplicateEvent } from "../../../api/querys/event-querys";
import { useQueryClient } from "@tanstack/react-query";
import { alertLoading } from "../../../ui/alert";

const EventListItemComponent = styled(EventListItemComponentStruct)`
  background-color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2rem;
  width: 100%;
  padding: 0.25rem 1rem;
  border-radius: ${(props) => props.theme.mediumBorderRadius};

  h4 {
    margin: 0;
  }

  div:nth-child(1) {
    width: 15rem;
  }
  div:nth-child(2) {
    width: 8rem;
  }
  div:nth-child(3) {
    width: 8rem;
  }
  div:nth-child(4) {
    width: 4rem;
    display: flex;
    justify-content: center;
  }
  div:nth-child(5) {
    width: 4rem;
    display: flex;
    justify-content: center;
  }
  div:nth-child(6) {
    width: 3rem;
    display: flex;
    justify-content: center;
  }
  .buttonSpace {
    justify-self: end;
    display: flex;
    height: 2rem;
    width: 4rem;
    gap: 0.5rem;
  }

  .editButton {
    justify-self: end;
    height: 2rem;
    width: 2rem;
    text-decoration: none;
    color: ${(props) => props.theme.black};
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
  }
`;

interface EventListItemComponentProps extends BaseType {
  event?: EventIntRsp;
}

function EventListItemComponentStruct(props: EventListItemComponentProps) {
  const { className, event } = props;

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const duplicateMutation = useIntDuplicateEvent(queryClient, navigate);

  let fromDate = event?.fromDate
    ? new Date(event.fromDate).toLocaleDateString()
    : undefined;
  let toDate = event?.toDate
    ? new Date(event.toDate).toLocaleDateString()
    : undefined;

  return (
    <div className={className}>
      <div>{event ? event.title : <h4>Titel</h4>} </div>
      <div>{event ? fromDate : <h4>Start</h4>} </div>
      <div>{event ? toDate : <h4>Ende</h4>} </div>
      <div>{event ? (event.price || 0) + "€" : <h4>Preis</h4>} </div>
      <div>
        {event?.id ? (
          event.registeredParticipantsCount +
          (event.maxParticipants !== null ? "/" + event.maxParticipants : "")
        ) : (
          <h4>Plätze</h4>
        )}
      </div>
      <div>
        {event ? (
          event.active ? (
            <BsCheckCircle color="green" />
          ) : (
            <BsXCircle color="red" />
          )
        ) : (
          <h4>Öffentlich</h4>
        )}
      </div>
      <div className="buttonSpace">
        <button
          disabled={!event?.id}
          className="editButton"
          onClick={() => {
            if (event?.id) {
              alertLoading(
                duplicateMutation.mutateAsync({ eventId: event.id })
              );
            }
          }}
        >
          <BiDuplicate display={!event?.id ? "none" : ""} />
        </button>
        <button
          disabled={!event?.id}
          className="editButton"
          onClick={() => {
            if (event?.id) {
              navigate(event.id);
            }
          }}
        >
          <BsPencilFill display={!event?.id ? "none" : ""} />
        </button>
      </div>
    </div>
  );
}

export default EventListItemComponent;
