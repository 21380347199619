import React from "react";
import styled from "styled-components";
import { EventRsp } from "../../api/generated";
import { EventRegistrationStatus } from "./event-registration-status";

const Details = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.secondaryDark};
  border-top: 1px solid ${(props) => props.theme.secondaryDark};

  padding: 0.8em 0 0.8em 0;

  height: 12rem;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;

  div {
    display: flex;
    min-width: 20%;
    align-items: center;
    justify-content: space-between;
    height: 30%;
  }
  div h5,
  div h4 {
    margin: 0;
  }

  div h4 {
    padding-right: 1rem;
  }

  @media only screen and (max-width: 1500px) {
    height: 10rem;
    div {
      height: 25%;
      min-width: 45%;
    }
  }

  @media only screen and (max-width: 900px) {
    flex-wrap: nowrap;
    height: auto;

    div {
      width: 100%;
      height: 2rem;
    }
  }
`;

export function EventDetailsComponent(props: { event: EventRsp }) {
  const {
    location,
    registrationDeadline,
    maxParticipants,
    fromDate,
    toDate,
    registrationStatus,
    registeredParticipantsCount,
    price,
    payed,
  } = props.event;

  return (
    <>
      <Details>
        <div>
          <h4>Von:</h4>{" "}
          <h5>
            {fromDate
              ? "von " +
                new Date(fromDate).toLocaleDateString() +
                " " +
                new Date(fromDate).toLocaleTimeString("de-DE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "Noch kein Datum festgelegt"}
          </h5>
        </div>
        <div>
          <h4>Bis:</h4>{" "}
          <h5>
            {toDate
              ? "von " +
                new Date(toDate).toLocaleDateString() +
                " " +
                new Date(toDate).toLocaleTimeString("de-DE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "Noch kein Datum festgelegt"}
          </h5>
        </div>
        <div>
          <h4>Kosten:</h4> <h5>{price ? price : 0}€</h5>
        </div>
        <div>
          <h4>Ort:</h4> <h5>{location}</h5>
        </div>
        {maxParticipants != null && registeredParticipantsCount != null ? (
          <>
            <div>
              <h4>Belegte Plätze:</h4>{" "}
              <h5>
                {registeredParticipantsCount}/{maxParticipants}
              </h5>
            </div>
          </>
        ) : (
          <></>
        )}
        <div>
          <h4>An- Abmeldeschluss:</h4>
          <h5>
            {registrationDeadline
              ? new Date(registrationDeadline).toLocaleDateString() +
                " " +
                new Date(registrationDeadline).toLocaleTimeString("de-DE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : ""}
          </h5>
        </div>
        <div>
          <h4>Ihr Status:</h4>{" "}
          <h5>
            <EventRegistrationStatus
              registrationStatus={registrationStatus}
              payed={payed}
            />
          </h5>
        </div>
      </Details>
    </>
  );
}
