import styled from "styled-components";
import React, { useEffect, useRef } from "react";

const HTMLTrimmerStyle = styled.div`
  overflow: hidden;

  p,
  ul {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;

//HTMLTrimmer is a component that trims the content of a html element to fit the container.
function HTMLTrimmer({
  description,
  className,
}: {
  description: string;
  className?: string;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    let didTrim = false;

    // Remove nodes until content fits within the container
    while (element.scrollHeight > element.clientHeight) {
      let didRemove = false;

      const treeWalker = document.createTreeWalker(
        element,
        NodeFilter.SHOW_TEXT
      );
      let node = treeWalker.lastChild();

      while (!didRemove && node) {
        if (node.textContent) {
          const words = node.textContent.split(" ");

          if (words.length > 1) {
            words.pop();
            node.textContent = words.join(" ");
            didRemove = true;
            didTrim = true;
          } else if (node.textContent.trim()) {
            node.textContent = "";
            didRemove = true;
            didTrim = true;
          }
        }

        if (didRemove) {
          let nextNode = node;
          while (
            nextNode &&
            !(nextNode.textContent && nextNode.textContent.trim())
          ) {
            const parent = nextNode.parentNode;
            if ("remove" in nextNode) {
              // @ts-ignore
              nextNode.remove();
            }

            // @ts-ignore
            nextNode = parent;
          }

          if (nextNode) {
            node = nextNode;
          }
        }

        if (node) {
          node = treeWalker.previousNode();
        }
      }
    }

    // After all excess nodes have been removed, append '...' to the last text node if any text was removed
    if (didTrim) {
      const treeWalker = document.createTreeWalker(
        element,
        NodeFilter.SHOW_TEXT
      );
      let node = treeWalker.lastChild();
      if (node && node.textContent) {
        node.textContent += "...";
      }
    }
  }, [description]);

  return (
    <HTMLTrimmerStyle
      className={"htmlTrimmer" + (className ? " " + className : "")}
      ref={ref}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
}

export default HTMLTrimmer;
