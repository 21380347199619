import React, { useState } from "react";
import styled from "styled-components";
import Paginierung from "../../../ui/paginierung";
import { BaseType } from "../../../../types/base-type";
import AddRegistrationsListItemComponent from "./add-registrations-list-item-component";
import { useGetEventCandidatesInt } from "../../../api/querys/event-registration-querys";

interface EditEventAddRegistrationsTabProps extends BaseType {
  eventId: string | undefined;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
`;

function EditEventAddRegistrationsTab(
  props: EditEventAddRegistrationsTabProps
) {
  const { eventId } = props;

  const [page, setPage] = useState(0);

  const { data, isLoading, isError } = useGetEventCandidatesInt(eventId, page);

  return (
    <>
      {isError ? (
        <>Ein Fehler ist aufgetreten</>
      ) : isLoading ? (
        <>Loading</>
      ) : (
        <StyledContent>
          <AddRegistrationsListItemComponent eventId={eventId!} />
          {data?.data.elements?.map((user, key) => {
            return (
              <AddRegistrationsListItemComponent
                user={user}
                eventId={eventId!}
                key={key}
              />
            );
          })}
        </StyledContent>
      )}
      <Paginierung
        page={page}
        setPage={setPage}
        totalPages={data?.data.totalPages}
        totalElements={data?.data.totalElements}
      ></Paginierung>
    </>
  );
}

export default EditEventAddRegistrationsTab;
