import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../components/ui/layout/header";
import Footer from "../../components/ui/layout/footer";
import { Link, useSearchParams } from "react-router-dom";
import { registerController } from "../../components/api/api-functions";

const RegisterConfirmationPage = styled.main`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-areas: "header" "content" "footer";
  grid-template-rows: 100px auto 50px;

  section {
    padding: 4rem;
    background: ${(props) => props.theme.secondaryLightest};
  }

  a {
    text-decoration: none;
    color: #387c49;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
`;

function RegisterConfirmationRoute() {
  let [searchParams] = useSearchParams();

  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    if (token && email) {
      registerController
        .confirmEmail({ token, email })
        .then(() => {
          setSuccess(true);
        })
        .catch(() => setSuccess(false));
    } else setSuccess(false);
  }, []);

  return (
    <Wrapper>
      <RegisterConfirmationPage>
        <Header />
        <section>
          {success == null ? (
            <>Loading</>
          ) : success ? (
            <>
              Registrierung erfolgreich.
              <Link to="/login"> Loggen Sie sich ein</Link>
            </>
          ) : (
            <>
              Etwas hat nicht funktioniert. Wenn das Problem bestehen bleibt
              wenden Sie sich bitte an einen Administrator.
            </>
          )}
        </section>
        <Footer />
      </RegisterConfirmationPage>
    </Wrapper>
  );
}

export default RegisterConfirmationRoute;
