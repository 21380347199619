/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbstractPageRspAccountIntRsp
 */
export interface AbstractPageRspAccountIntRsp {
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspAccountIntRsp
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspAccountIntRsp
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspAccountIntRsp
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspAccountIntRsp
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspAccountIntRsp
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<AccountIntRsp>}
     * @memberof AbstractPageRspAccountIntRsp
     */
    'elements'?: Array<AccountIntRsp>;
}
/**
 * 
 * @export
 * @interface AbstractPageRspEventIntRsp
 */
export interface AbstractPageRspEventIntRsp {
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventIntRsp
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventIntRsp
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventIntRsp
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventIntRsp
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventIntRsp
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<EventIntRsp>}
     * @memberof AbstractPageRspEventIntRsp
     */
    'elements'?: Array<EventIntRsp>;
}
/**
 * 
 * @export
 * @interface AbstractPageRspEventRegistrationIntRsp
 */
export interface AbstractPageRspEventRegistrationIntRsp {
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRegistrationIntRsp
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRegistrationIntRsp
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRegistrationIntRsp
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRegistrationIntRsp
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRegistrationIntRsp
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<EventRegistrationIntRsp>}
     * @memberof AbstractPageRspEventRegistrationIntRsp
     */
    'elements'?: Array<EventRegistrationIntRsp>;
}
/**
 * 
 * @export
 * @interface AbstractPageRspEventRsp
 */
export interface AbstractPageRspEventRsp {
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRsp
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRsp
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRsp
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRsp
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractPageRspEventRsp
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<EventRsp>}
     * @memberof AbstractPageRspEventRsp
     */
    'elements'?: Array<EventRsp>;
}
/**
 * 
 * @export
 * @interface AccountIntRsp
 */
export interface AccountIntRsp {
    /**
     * 
     * @type {string}
     * @memberof AccountIntRsp
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIntRsp
     */
    'role'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountIntRsp
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountIntRsp
     */
    'emailVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountIntRsp
     */
    'approved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountIntRsp
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIntRsp
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIntRsp
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIntRsp
     */
    'ljvNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIntRsp
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {AddressRsp}
     * @memberof AccountIntRsp
     */
    'address'?: AddressRsp;
}
/**
 * 
 * @export
 * @interface AccountMeRsp
 */
export interface AccountMeRsp {
    /**
     * 
     * @type {string}
     * @memberof AccountMeRsp
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeRsp
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeRsp
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeRsp
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeRsp
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeRsp
     */
    'ljvNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeRsp
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {AddressRsp}
     * @memberof AccountMeRsp
     */
    'address'?: AddressRsp;
    /**
     * 
     * @type {boolean}
     * @memberof AccountMeRsp
     */
    'impersonated'?: boolean;
}
/**
 * 
 * @export
 * @interface AddEventAssetReq
 */
export interface AddEventAssetReq {
    /**
     * 
     * @type {string}
     * @memberof AddEventAssetReq
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEventAssetReq
     */
    'assetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEventAssetReq
     */
    'assetType'?: AddEventAssetReqAssetTypeEnum;
}

export const AddEventAssetReqAssetTypeEnum = {
    MainPicture: 'MAIN_PICTURE',
    Material: 'MATERIAL'
} as const;

export type AddEventAssetReqAssetTypeEnum = typeof AddEventAssetReqAssetTypeEnum[keyof typeof AddEventAssetReqAssetTypeEnum];

/**
 * 
 * @export
 * @interface AddEventAssetsReq
 */
export interface AddEventAssetsReq {
    /**
     * 
     * @type {Array<AddEventAssetReq>}
     * @memberof AddEventAssetsReq
     */
    'assets': Array<AddEventAssetReq>;
}
/**
 * 
 * @export
 * @interface AddressRsp
 */
export interface AddressRsp {
    /**
     * 
     * @type {string}
     * @memberof AddressRsp
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRsp
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRsp
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRsp
     */
    'street'?: string;
}
/**
 * 
 * @export
 * @interface AssetRsp
 */
export interface AssetRsp {
    /**
     * 
     * @type {string}
     * @memberof AssetRsp
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetRsp
     */
    'fileExtension'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetRsp
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetRsp
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssetRsp
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetRsp
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface ConfirmAccountReq
 */
export interface ConfirmAccountReq {
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountReq
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CreateAccountIntReq
 */
export interface CreateAccountIntReq {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'ljvNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountIntReq
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountIntReq
     */
    'approved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountIntReq
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'role': CreateAccountIntReqRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountIntReq
     */
    'street'?: string;
}

export const CreateAccountIntReqRoleEnum = {
    Admin: 'ADMIN',
    Member: 'MEMBER'
} as const;

export type CreateAccountIntReqRoleEnum = typeof CreateAccountIntReqRoleEnum[keyof typeof CreateAccountIntReqRoleEnum];

/**
 * 
 * @export
 * @interface CreateEventIntReq
 */
export interface CreateEventIntReq {
    /**
     * 
     * @type {string}
     * @memberof CreateEventIntReq
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventIntReq
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventIntReq
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEventIntReq
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEventIntReq
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEventIntReq
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventIntReq
     */
    'toDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEventIntReq
     */
    'registrationNeeded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEventIntReq
     */
    'registrationDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEventIntReq
     */
    'maxParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEventIntReq
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEventIntReq
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEventIntReq
     */
    'autoAcceptRegistration'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEventIntReq
     */
    'acceptMailText'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEventIntReq
     */
    'commentEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEventIntReq
     */
    'triggerNewEventNotification'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateRegistrationIntReq
 */
export interface CreateRegistrationIntReq {
    /**
     * 
     * @type {string}
     * @memberof CreateRegistrationIntReq
     */
    'accountId'?: string;
}
/**
 * 
 * @export
 * @interface CreateRegistrationReq
 */
export interface CreateRegistrationReq {
    /**
     * 
     * @type {string}
     * @memberof CreateRegistrationReq
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface EventAssetRsp
 */
export interface EventAssetRsp {
    /**
     * 
     * @type {string}
     * @memberof EventAssetRsp
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventAssetRsp
     */
    'fileExtension'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventAssetRsp
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventAssetRsp
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventAssetRsp
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventAssetRsp
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventAssetRsp
     */
    'eventAssetType'?: EventAssetRspEventAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventAssetRsp
     */
    'eventAssetName'?: string;
}

export const EventAssetRspEventAssetTypeEnum = {
    MainPicture: 'MAIN_PICTURE',
    Material: 'MATERIAL'
} as const;

export type EventAssetRspEventAssetTypeEnum = typeof EventAssetRspEventAssetTypeEnum[keyof typeof EventAssetRspEventAssetTypeEnum];

/**
 * 
 * @export
 * @interface EventIntRsp
 */
export interface EventIntRsp {
    /**
     * 
     * @type {string}
     * @memberof EventIntRsp
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventIntRsp
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventIntRsp
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventIntRsp
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventIntRsp
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventIntRsp
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventIntRsp
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventIntRsp
     */
    'toDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventIntRsp
     */
    'registrationNeeded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventIntRsp
     */
    'registrationDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventIntRsp
     */
    'maxParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventIntRsp
     */
    'registeredParticipantsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventIntRsp
     */
    'price'?: number;
    /**
     * 
     * @type {Array<EventAssetRsp>}
     * @memberof EventIntRsp
     */
    'assets'?: Array<EventAssetRsp>;
    /**
     * 
     * @type {boolean}
     * @memberof EventIntRsp
     */
    'commentEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventIntRsp
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventIntRsp
     */
    'autoAcceptRegistration'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventIntRsp
     */
    'acceptMailText'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventIntRsp
     */
    'triggerNewEventNotification'?: boolean;
}
/**
 * 
 * @export
 * @interface EventRegistrationIntRsp
 */
export interface EventRegistrationIntRsp {
    /**
     * 
     * @type {string}
     * @memberof EventRegistrationIntRsp
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventRegistrationIntRsp
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventRegistrationIntRsp
     */
    'eventTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventRegistrationIntRsp
     */
    'status'?: EventRegistrationIntRspStatusEnum;
    /**
     * 
     * @type {AccountIntRsp}
     * @memberof EventRegistrationIntRsp
     */
    'participant'?: AccountIntRsp;
    /**
     * 
     * @type {string}
     * @memberof EventRegistrationIntRsp
     */
    'registeredAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventRegistrationIntRsp
     */
    'payed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventRegistrationIntRsp
     */
    'comment'?: string;
}

export const EventRegistrationIntRspStatusEnum = {
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;

export type EventRegistrationIntRspStatusEnum = typeof EventRegistrationIntRspStatusEnum[keyof typeof EventRegistrationIntRspStatusEnum];

/**
 * 
 * @export
 * @interface EventRsp
 */
export interface EventRsp {
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventRsp
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventRsp
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'toDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventRsp
     */
    'registrationNeeded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'registrationDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventRsp
     */
    'maxParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventRsp
     */
    'registeredParticipantsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventRsp
     */
    'price'?: number;
    /**
     * 
     * @type {Array<EventAssetRsp>}
     * @memberof EventRsp
     */
    'assets'?: Array<EventAssetRsp>;
    /**
     * 
     * @type {boolean}
     * @memberof EventRsp
     */
    'commentEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'registrationStatus'?: EventRspRegistrationStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EventRsp
     */
    'payed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventRsp
     */
    'comment'?: string;
}

export const EventRspRegistrationStatusEnum = {
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;

export type EventRspRegistrationStatusEnum = typeof EventRspRegistrationStatusEnum[keyof typeof EventRspRegistrationStatusEnum];

/**
 * 
 * @export
 * @interface NotificationRsp
 */
export interface NotificationRsp {
    /**
     * 
     * @type {string}
     * @memberof NotificationRsp
     */
    'setting'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRsp
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRsp
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationRsp
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettingsRsp
 */
export interface NotificationSettingsRsp {
    /**
     * 
     * @type {Array<NotificationRsp>}
     * @memberof NotificationSettingsRsp
     */
    'notifications'?: Array<NotificationRsp>;
}
/**
 * 
 * @export
 * @interface PageReq
 */
export interface PageReq {
    /**
     * 
     * @type {number}
     * @memberof PageReq
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReq
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof PageReq
     */
    'search'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageReq
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageReq
     */
    'sortDirection'?: PageReqSortDirectionEnum;
}

export const PageReqSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PageReqSortDirectionEnum = typeof PageReqSortDirectionEnum[keyof typeof PageReqSortDirectionEnum];

/**
 * 
 * @export
 * @interface RegisterAccountReq
 */
export interface RegisterAccountReq {
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'ljvNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountReq
     */
    'street': string;
}
/**
 * 
 * @export
 * @interface UpdateAccountIntReq
 */
export interface UpdateAccountIntReq {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'ljvNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountIntReq
     */
    'emailVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountIntReq
     */
    'approved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'role'?: UpdateAccountIntReqRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountIntReq
     */
    'street'?: string;
}

export const UpdateAccountIntReqRoleEnum = {
    Admin: 'ADMIN',
    Member: 'MEMBER'
} as const;

export type UpdateAccountIntReqRoleEnum = typeof UpdateAccountIntReqRoleEnum[keyof typeof UpdateAccountIntReqRoleEnum];

/**
 * 
 * @export
 * @interface UpdateAccountMeReq
 */
export interface UpdateAccountMeReq {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountMeReq
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountMeReq
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountMeReq
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountMeReq
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountMeReq
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountMeReq
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountMeReq
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountMeReq
     */
    'street'?: string;
}
/**
 * 
 * @export
 * @interface UpdateEventIntReq
 */
export interface UpdateEventIntReq {
    /**
     * 
     * @type {string}
     * @memberof UpdateEventIntReq
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventIntReq
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventIntReq
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEventIntReq
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEventIntReq
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventIntReq
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventIntReq
     */
    'toDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEventIntReq
     */
    'registrationNeeded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventIntReq
     */
    'registrationDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEventIntReq
     */
    'maxParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateEventIntReq
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEventIntReq
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEventIntReq
     */
    'autoAcceptRegistration'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventIntReq
     */
    'acceptMailText'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEventIntReq
     */
    'commentEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEventIntReq
     */
    'triggerNewEventNotification'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateNotificationSettingsReq
 */
export interface UpdateNotificationSettingsReq {
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationSettingsReq
     */
    'setting'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationSettingsReq
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateRegistrationReq
 */
export interface UpdateRegistrationReq {
    /**
     * 
     * @type {string}
     * @memberof UpdateRegistrationReq
     */
    'status'?: UpdateRegistrationReqStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRegistrationReq
     */
    'payed'?: boolean;
}

export const UpdateRegistrationReqStatusEnum = {
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED'
} as const;

export type UpdateRegistrationReqStatusEnum = typeof UpdateRegistrationReqStatusEnum[keyof typeof UpdateRegistrationReqStatusEnum];


/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrincipal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrincipal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAccountMeReq} updateAccountMeReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrincipal: async (updateAccountMeReq: UpdateAccountMeReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountMeReq' is not null or undefined
            assertParamExists('updatePrincipal', 'updateAccountMeReq', updateAccountMeReq)
            const localVarPath = `/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountMeReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePrincipal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePrincipal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrincipal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountMeRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrincipal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAccountMeReq} updateAccountMeReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrincipal(updateAccountMeReq: UpdateAccountMeReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountMeRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrincipal(updateAccountMeReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrincipal(options?: any): AxiosPromise<void> {
            return localVarFp.deletePrincipal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrincipal(options?: any): AxiosPromise<AccountMeRsp> {
            return localVarFp.getPrincipal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAccountMeReq} updateAccountMeReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrincipal(updateAccountMeReq: UpdateAccountMeReq, options?: any): AxiosPromise<AccountMeRsp> {
            return localVarFp.updatePrincipal(updateAccountMeReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public deletePrincipal(options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).deletePrincipal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public getPrincipal(options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).getPrincipal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAccountMeReq} updateAccountMeReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public updatePrincipal(updateAccountMeReq: UpdateAccountMeReq, options?: AxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).updatePrincipal(updateAccountMeReq, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountIntControllerApi - axios parameter creator
 * @export
 */
export const AccountIntControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('_delete', 'accountId', accountId)
            const localVarPath = `/internal/user/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAccountIntReq} createAccountIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createAccountIntReq: CreateAccountIntReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountIntReq' is not null or undefined
            assertParamExists('create', 'createAccountIntReq', createAccountIntReq)
            const localVarPath = `/internal/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountIntReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (req: PageReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('getAll', 'req', req)
            const localVarPath = `/internal/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getById', 'accountId', accountId)
            const localVarPath = `/internal/user/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('resetPassword', 'accountId', accountId)
            const localVarPath = `/internal/user/{accountId}/password`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {UpdateAccountIntReq} updateAccountIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (accountId: string, updateAccountIntReq: UpdateAccountIntReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('update', 'accountId', accountId)
            // verify required parameter 'updateAccountIntReq' is not null or undefined
            assertParamExists('update', 'updateAccountIntReq', updateAccountIntReq)
            const localVarPath = `/internal/user/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountIntReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountIntControllerApi - functional programming interface
 * @export
 */
export const AccountIntControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountIntControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAccountIntReq} createAccountIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createAccountIntReq: CreateAccountIntReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createAccountIntReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(req: PageReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageRspAccountIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {UpdateAccountIntReq} updateAccountIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(accountId: string, updateAccountIntReq: UpdateAccountIntReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(accountId, updateAccountIntReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountIntControllerApi - factory interface
 * @export
 */
export const AccountIntControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountIntControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(accountId: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAccountIntReq} createAccountIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createAccountIntReq: CreateAccountIntReq, options?: any): AxiosPromise<AccountIntRsp> {
            return localVarFp.create(createAccountIntReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(req: PageReq, options?: any): AxiosPromise<AbstractPageRspAccountIntRsp> {
            return localVarFp.getAll(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(accountId: string, options?: any): AxiosPromise<AccountIntRsp> {
            return localVarFp.getById(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(accountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {UpdateAccountIntReq} updateAccountIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(accountId: string, updateAccountIntReq: UpdateAccountIntReq, options?: any): AxiosPromise<AccountIntRsp> {
            return localVarFp.update(accountId, updateAccountIntReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountIntControllerApi - object-oriented interface
 * @export
 * @class AccountIntControllerApi
 * @extends {BaseAPI}
 */
export class AccountIntControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountIntControllerApi
     */
    public _delete(accountId: string, options?: AxiosRequestConfig) {
        return AccountIntControllerApiFp(this.configuration)._delete(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAccountIntReq} createAccountIntReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountIntControllerApi
     */
    public create(createAccountIntReq: CreateAccountIntReq, options?: AxiosRequestConfig) {
        return AccountIntControllerApiFp(this.configuration).create(createAccountIntReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageReq} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountIntControllerApi
     */
    public getAll(req: PageReq, options?: AxiosRequestConfig) {
        return AccountIntControllerApiFp(this.configuration).getAll(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountIntControllerApi
     */
    public getById(accountId: string, options?: AxiosRequestConfig) {
        return AccountIntControllerApiFp(this.configuration).getById(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountIntControllerApi
     */
    public resetPassword(accountId: string, options?: AxiosRequestConfig) {
        return AccountIntControllerApiFp(this.configuration).resetPassword(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {UpdateAccountIntReq} updateAccountIntReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountIntControllerApi
     */
    public update(accountId: string, updateAccountIntReq: UpdateAccountIntReq, options?: AxiosRequestConfig) {
        return AccountIntControllerApiFp(this.configuration).update(accountId, updateAccountIntReq, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetControllerApi - axios parameter creator
 * @export
 */
export const AssetControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('download', 'assetId', assetId)
            const localVarPath = `/asset/{assetId}/download`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAsset', 'assetId', assetId)
            const localVarPath = `/asset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetControllerApi - functional programming interface
 * @export
 */
export const AssetControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetControllerApi - factory interface
 * @export
 */
export const AssetControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(assetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.download(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(assetId: string, options?: any): AxiosPromise<AssetRsp> {
            return localVarFp.getAsset(assetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetControllerApi - object-oriented interface
 * @export
 * @class AssetControllerApi
 * @extends {BaseAPI}
 */
export class AssetControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public download(assetId: string, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).download(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetControllerApi
     */
    public getAsset(assetId: string, options?: AxiosRequestConfig) {
        return AssetControllerApiFp(this.configuration).getAsset(assetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetIntControllerApi - axios parameter creator
 * @export
 */
export const AssetIntControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteAsset', 'assetId', assetId)
            const localVarPath = `/internal/asset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('upload', 'file', file)
            const localVarPath = `/internal/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetIntControllerApi - functional programming interface
 * @export
 */
export const AssetIntControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetIntControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAsset(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAsset(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upload(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetIntControllerApi - factory interface
 * @export
 */
export const AssetIntControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetIntControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(assetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAsset(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(file: File, options?: any): AxiosPromise<AssetRsp> {
            return localVarFp.upload(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetIntControllerApi - object-oriented interface
 * @export
 * @class AssetIntControllerApi
 * @extends {BaseAPI}
 */
export class AssetIntControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetIntControllerApi
     */
    public deleteAsset(assetId: string, options?: AxiosRequestConfig) {
        return AssetIntControllerApiFp(this.configuration).deleteAsset(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetIntControllerApi
     */
    public upload(file: File, options?: AxiosRequestConfig) {
        return AssetIntControllerApiFp(this.configuration).upload(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventAssetIntControllerApi - axios parameter creator
 * @export
 */
export const EventAssetIntControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} eventId 
         * @param {AddEventAssetsReq} addEventAssetsReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAsset: async (eventId: string, addEventAssetsReq: AddEventAssetsReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('addAsset', 'eventId', eventId)
            // verify required parameter 'addEventAssetsReq' is not null or undefined
            assertParamExists('addAsset', 'addEventAssetsReq', addEventAssetsReq)
            const localVarPath = `/internal/event/{eventId}/asset`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addEventAssetsReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAsset: async (eventId: string, assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('removeAsset', 'eventId', eventId)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('removeAsset', 'assetId', assetId)
            const localVarPath = `/internal/event/{eventId}/asset/{assetId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset: async (assetId: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('updateAsset', 'assetId', assetId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateAsset', 'body', body)
            const localVarPath = `/internal/event/asset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventAssetIntControllerApi - functional programming interface
 * @export
 */
export const EventAssetIntControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventAssetIntControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} eventId 
         * @param {AddEventAssetsReq} addEventAssetsReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAsset(eventId: string, addEventAssetsReq: AddEventAssetsReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAsset(eventId, addEventAssetsReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAsset(eventId: string, assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAsset(eventId, assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAsset(assetId: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAsset(assetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventAssetIntControllerApi - factory interface
 * @export
 */
export const EventAssetIntControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventAssetIntControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} eventId 
         * @param {AddEventAssetsReq} addEventAssetsReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAsset(eventId: string, addEventAssetsReq: AddEventAssetsReq, options?: any): AxiosPromise<void> {
            return localVarFp.addAsset(eventId, addEventAssetsReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAsset(eventId: string, assetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeAsset(eventId, assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset(assetId: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.updateAsset(assetId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventAssetIntControllerApi - object-oriented interface
 * @export
 * @class EventAssetIntControllerApi
 * @extends {BaseAPI}
 */
export class EventAssetIntControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} eventId 
     * @param {AddEventAssetsReq} addEventAssetsReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAssetIntControllerApi
     */
    public addAsset(eventId: string, addEventAssetsReq: AddEventAssetsReq, options?: AxiosRequestConfig) {
        return EventAssetIntControllerApiFp(this.configuration).addAsset(eventId, addEventAssetsReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {string} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAssetIntControllerApi
     */
    public removeAsset(eventId: string, assetId: string, options?: AxiosRequestConfig) {
        return EventAssetIntControllerApiFp(this.configuration).removeAsset(eventId, assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAssetIntControllerApi
     */
    public updateAsset(assetId: string, body: object, options?: AxiosRequestConfig) {
        return EventAssetIntControllerApiFp(this.configuration).updateAsset(assetId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventControllerApi - axios parameter creator
 * @export
 */
export const EventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll2: async (req: PageReq, filterPastEvents?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('getAll2', 'req', req)
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }

            if (filterPastEvents !== undefined) {
                localVarQueryParameter['filterPastEvents'] = filterPastEvents;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById2: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getById2', 'eventId', eventId)
            const localVarPath = `/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistered: async (req: PageReq, filterPastEvents?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('getRegistered', 'req', req)
            const localVarPath = `/event/registered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }

            if (filterPastEvents !== undefined) {
                localVarQueryParameter['filterPastEvents'] = filterPastEvents;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {CreateRegistrationReq} createRegistrationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (eventId: string, createRegistrationReq: CreateRegistrationReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('register', 'eventId', eventId)
            // verify required parameter 'createRegistrationReq' is not null or undefined
            assertParamExists('register', 'createRegistrationReq', createRegistrationReq)
            const localVarPath = `/event/{eventId}/register`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRegistrationReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregister: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('unregister', 'eventId', eventId)
            const localVarPath = `/event/{eventId}/register`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventControllerApi - functional programming interface
 * @export
 */
export const EventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll2(req: PageReq, filterPastEvents?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageRspEventRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll2(req, filterPastEvents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById2(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById2(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistered(req: PageReq, filterPastEvents?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageRspEventRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegistered(req, filterPastEvents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {CreateRegistrationReq} createRegistrationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(eventId: string, createRegistrationReq: CreateRegistrationReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(eventId, createRegistrationReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unregister(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unregister(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventControllerApi - factory interface
 * @export
 */
export const EventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll2(req: PageReq, filterPastEvents?: boolean, options?: any): AxiosPromise<AbstractPageRspEventRsp> {
            return localVarFp.getAll2(req, filterPastEvents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById2(eventId: string, options?: any): AxiosPromise<EventRsp> {
            return localVarFp.getById2(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistered(req: PageReq, filterPastEvents?: boolean, options?: any): AxiosPromise<AbstractPageRspEventRsp> {
            return localVarFp.getRegistered(req, filterPastEvents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {CreateRegistrationReq} createRegistrationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(eventId: string, createRegistrationReq: CreateRegistrationReq, options?: any): AxiosPromise<void> {
            return localVarFp.register(eventId, createRegistrationReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregister(eventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unregister(eventId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventControllerApi - object-oriented interface
 * @export
 * @class EventControllerApi
 * @extends {BaseAPI}
 */
export class EventControllerApi extends BaseAPI {
    /**
     * 
     * @param {PageReq} req 
     * @param {boolean} [filterPastEvents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getAll2(req: PageReq, filterPastEvents?: boolean, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getAll2(req, filterPastEvents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getById2(eventId: string, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getById2(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageReq} req 
     * @param {boolean} [filterPastEvents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getRegistered(req: PageReq, filterPastEvents?: boolean, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getRegistered(req, filterPastEvents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {CreateRegistrationReq} createRegistrationReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public register(eventId: string, createRegistrationReq: CreateRegistrationReq, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).register(eventId, createRegistrationReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public unregister(eventId: string, options?: AxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).unregister(eventId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventIntControllerApi - axios parameter creator
 * @export
 */
export const EventIntControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEventIntReq} createEventIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (createEventIntReq: CreateEventIntReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEventIntReq' is not null or undefined
            assertParamExists('create1', 'createEventIntReq', createEventIntReq)
            const localVarPath = `/internal/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventIntReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {CreateRegistrationIntReq} createRegistrationIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventRegistration: async (eventId: string, createRegistrationIntReq: CreateRegistrationIntReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('createEventRegistration', 'eventId', eventId)
            // verify required parameter 'createRegistrationIntReq' is not null or undefined
            assertParamExists('createEventRegistration', 'createRegistrationIntReq', createRegistrationIntReq)
            const localVarPath = `/internal/event/{eventId}/registrations`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRegistrationIntReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('delete1', 'eventId', eventId)
            const localVarPath = `/internal/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegistration: async (eventId: string, registrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteRegistration', 'eventId', eventId)
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('deleteRegistration', 'registrationId', registrationId)
            const localVarPath = `/internal/event/{eventId}/registrations/{registrationId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRegistrationsEvent: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('downloadRegistrationsEvent', 'eventId', eventId)
            const localVarPath = `/internal/event/{eventId}/registrations/download`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll1: async (req: PageReq, filterPastEvents?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('getAll1', 'req', req)
            const localVarPath = `/internal/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }

            if (filterPastEvents !== undefined) {
                localVarQueryParameter['filterPastEvents'] = filterPastEvents;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPendingRegistrations: async (req: PageReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('getAllPendingRegistrations', 'req', req)
            const localVarPath = `/internal/event/registrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getById1', 'eventId', eventId)
            const localVarPath = `/internal/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventParticipants: async (eventId: string, req: PageReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventParticipants', 'eventId', eventId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('getEventParticipants', 'req', req)
            const localVarPath = `/internal/event/{eventId}/registrations`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEventCandidates: async (eventId: string, req: PageReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('loadEventCandidates', 'eventId', eventId)
            // verify required parameter 'req' is not null or undefined
            assertParamExists('loadEventCandidates', 'req', req)
            const localVarPath = `/internal/event/{eventId}/registrations/candidates`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {UpdateEventIntReq} updateEventIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (eventId: string, updateEventIntReq: UpdateEventIntReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('update1', 'eventId', eventId)
            // verify required parameter 'updateEventIntReq' is not null or undefined
            assertParamExists('update1', 'updateEventIntReq', updateEventIntReq)
            const localVarPath = `/internal/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventIntReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} registrationId 
         * @param {UpdateRegistrationReq} updateRegistrationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegistration: async (eventId: string, registrationId: string, updateRegistrationReq: UpdateRegistrationReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateRegistration', 'eventId', eventId)
            // verify required parameter 'registrationId' is not null or undefined
            assertParamExists('updateRegistration', 'registrationId', registrationId)
            // verify required parameter 'updateRegistrationReq' is not null or undefined
            assertParamExists('updateRegistration', 'updateRegistrationReq', updateRegistrationReq)
            const localVarPath = `/internal/event/{eventId}/registrations/{registrationId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(registrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRegistrationReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventIntControllerApi - functional programming interface
 * @export
 */
export const EventIntControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventIntControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEventIntReq} createEventIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(createEventIntReq: CreateEventIntReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create1(createEventIntReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {CreateRegistrationIntReq} createRegistrationIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEventRegistration(eventId: string, createRegistrationIntReq: CreateRegistrationIntReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventRegistrationIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventRegistration(eventId, createRegistrationIntReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete1(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRegistration(eventId: string, registrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRegistration(eventId, registrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRegistrationsEvent(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRegistrationsEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll1(req: PageReq, filterPastEvents?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageRspEventIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll1(req, filterPastEvents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPendingRegistrations(req: PageReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageRspEventRegistrationIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPendingRegistrations(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById1(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById1(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventParticipants(eventId: string, req: PageReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageRspEventRegistrationIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventParticipants(eventId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadEventCandidates(eventId: string, req: PageReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageRspAccountIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadEventCandidates(eventId, req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {UpdateEventIntReq} updateEventIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(eventId: string, updateEventIntReq: UpdateEventIntReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(eventId, updateEventIntReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} registrationId 
         * @param {UpdateRegistrationReq} updateRegistrationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegistration(eventId: string, registrationId: string, updateRegistrationReq: UpdateRegistrationReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventRegistrationIntRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegistration(eventId, registrationId, updateRegistrationReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventIntControllerApi - factory interface
 * @export
 */
export const EventIntControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventIntControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEventIntReq} createEventIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(createEventIntReq: CreateEventIntReq, options?: any): AxiosPromise<EventIntRsp> {
            return localVarFp.create1(createEventIntReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {CreateRegistrationIntReq} createRegistrationIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventRegistration(eventId: string, createRegistrationIntReq: CreateRegistrationIntReq, options?: any): AxiosPromise<EventRegistrationIntRsp> {
            return localVarFp.createEventRegistration(eventId, createRegistrationIntReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(eventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.delete1(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} registrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegistration(eventId: string, registrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRegistration(eventId, registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRegistrationsEvent(eventId: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadRegistrationsEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {boolean} [filterPastEvents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll1(req: PageReq, filterPastEvents?: boolean, options?: any): AxiosPromise<AbstractPageRspEventIntRsp> {
            return localVarFp.getAll1(req, filterPastEvents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPendingRegistrations(req: PageReq, options?: any): AxiosPromise<AbstractPageRspEventRegistrationIntRsp> {
            return localVarFp.getAllPendingRegistrations(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1(eventId: string, options?: any): AxiosPromise<EventIntRsp> {
            return localVarFp.getById1(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventParticipants(eventId: string, req: PageReq, options?: any): AxiosPromise<AbstractPageRspEventRegistrationIntRsp> {
            return localVarFp.getEventParticipants(eventId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {PageReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadEventCandidates(eventId: string, req: PageReq, options?: any): AxiosPromise<AbstractPageRspAccountIntRsp> {
            return localVarFp.loadEventCandidates(eventId, req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {UpdateEventIntReq} updateEventIntReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(eventId: string, updateEventIntReq: UpdateEventIntReq, options?: any): AxiosPromise<EventIntRsp> {
            return localVarFp.update1(eventId, updateEventIntReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId 
         * @param {string} registrationId 
         * @param {UpdateRegistrationReq} updateRegistrationReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegistration(eventId: string, registrationId: string, updateRegistrationReq: UpdateRegistrationReq, options?: any): AxiosPromise<EventRegistrationIntRsp> {
            return localVarFp.updateRegistration(eventId, registrationId, updateRegistrationReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventIntControllerApi - object-oriented interface
 * @export
 * @class EventIntControllerApi
 * @extends {BaseAPI}
 */
export class EventIntControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateEventIntReq} createEventIntReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public create1(createEventIntReq: CreateEventIntReq, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).create1(createEventIntReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {CreateRegistrationIntReq} createRegistrationIntReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public createEventRegistration(eventId: string, createRegistrationIntReq: CreateRegistrationIntReq, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).createEventRegistration(eventId, createRegistrationIntReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public delete1(eventId: string, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).delete1(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {string} registrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public deleteRegistration(eventId: string, registrationId: string, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).deleteRegistration(eventId, registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public downloadRegistrationsEvent(eventId: string, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).downloadRegistrationsEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageReq} req 
     * @param {boolean} [filterPastEvents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public getAll1(req: PageReq, filterPastEvents?: boolean, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).getAll1(req, filterPastEvents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageReq} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public getAllPendingRegistrations(req: PageReq, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).getAllPendingRegistrations(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public getById1(eventId: string, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).getById1(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {PageReq} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public getEventParticipants(eventId: string, req: PageReq, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).getEventParticipants(eventId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {PageReq} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public loadEventCandidates(eventId: string, req: PageReq, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).loadEventCandidates(eventId, req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {UpdateEventIntReq} updateEventIntReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public update1(eventId: string, updateEventIntReq: UpdateEventIntReq, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).update1(eventId, updateEventIntReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId 
     * @param {string} registrationId 
     * @param {UpdateRegistrationReq} updateRegistrationReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventIntControllerApi
     */
    public updateRegistration(eventId: string, registrationId: string, updateRegistrationReq: UpdateRegistrationReq, options?: AxiosRequestConfig) {
        return EventIntControllerApiFp(this.configuration).updateRegistration(eventId, registrationId, updateRegistrationReq, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNotificationSettingsReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSettings: async (req: UpdateNotificationSettingsReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('updateNotificationSettings', 'req', req)
            const localVarPath = `/notification/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingsRsp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateNotificationSettingsReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationSettings(req: UpdateNotificationSettingsReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationSettings(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSettings(options?: any): AxiosPromise<NotificationSettingsRsp> {
            return localVarFp.getNotificationSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNotificationSettingsReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSettings(req: UpdateNotificationSettingsReq, options?: any): AxiosPromise<void> {
            return localVarFp.updateNotificationSettings(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getNotificationSettings(options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).getNotificationSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateNotificationSettingsReq} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public updateNotificationSettings(req: UpdateNotificationSettingsReq, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).updateNotificationSettings(req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegisterControllerApi - axios parameter creator
 * @export
 */
export const RegisterControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConfirmAccountReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: async (req: ConfirmAccountReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('confirmEmail', 'req', req)
            const localVarPath = `/register/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (req !== undefined) {
                localVarQueryParameter['req'] = req;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterAccountReq} registerAccountReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAccount: async (registerAccountReq: RegisterAccountReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerAccountReq' is not null or undefined
            assertParamExists('registerAccount', 'registerAccountReq', registerAccountReq)
            const localVarPath = `/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerAccountReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword1: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resetPassword1', 'email', email)
            const localVarPath = `/register/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegisterControllerApi - functional programming interface
 * @export
 */
export const RegisterControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegisterControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConfirmAccountReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmail(req: ConfirmAccountReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmail(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterAccountReq} registerAccountReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAccount(registerAccountReq: RegisterAccountReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAccount(registerAccountReq, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword1(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword1(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegisterControllerApi - factory interface
 * @export
 */
export const RegisterControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegisterControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ConfirmAccountReq} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(req: ConfirmAccountReq, options?: any): AxiosPromise<void> {
            return localVarFp.confirmEmail(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterAccountReq} registerAccountReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAccount(registerAccountReq: RegisterAccountReq, options?: any): AxiosPromise<void> {
            return localVarFp.registerAccount(registerAccountReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword1(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword1(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegisterControllerApi - object-oriented interface
 * @export
 * @class RegisterControllerApi
 * @extends {BaseAPI}
 */
export class RegisterControllerApi extends BaseAPI {
    /**
     * 
     * @param {ConfirmAccountReq} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterControllerApi
     */
    public confirmEmail(req: ConfirmAccountReq, options?: AxiosRequestConfig) {
        return RegisterControllerApiFp(this.configuration).confirmEmail(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterAccountReq} registerAccountReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterControllerApi
     */
    public registerAccount(registerAccountReq: RegisterAccountReq, options?: AxiosRequestConfig) {
        return RegisterControllerApiFp(this.configuration).registerAccount(registerAccountReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterControllerApi
     */
    public resetPassword1(email: string, options?: AxiosRequestConfig) {
        return RegisterControllerApiFp(this.configuration).resetPassword1(email, options).then((request) => request(this.axios, this.basePath));
    }
}


