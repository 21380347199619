import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { accountIntController } from "../api-functions";
import {
  CreateAccountIntReq,
  PageReq,
  UpdateAccountIntReq,
} from "../generated";
import { NavigateFunction } from "react-router-dom";

export const useGetUsersInt = (pageData: PageReq) =>
  useQuery({
    queryKey: ["users", pageData.page, pageData.search],
    queryFn: async () => {
      return await accountIntController.getAll(pageData);
    },
  });

export const useGetUserByIdInt = (
  id: string | undefined,
  enabled: boolean = true
) =>
  useQuery({
    queryKey: ["users", id],
    queryFn: async () => {
      return id != null ? await accountIntController.getById(id!) : null;
    },
    refetchOnWindowFocus: false,
    enabled,
  });

export const useUpdateUserInt = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async (props: {
      req: UpdateAccountIntReq;
      userId: string | undefined;
      passwordRepeat: string | undefined;
    }) => {
      const { req, passwordRepeat, userId } = props;
      if (req.password && req.password !== passwordRepeat) {
        throw new Error("Passwörter stimmen nicht überein");
      }
      if (userId == null) throw new Error("Keine Nutzer Id");

      return await accountIntController.update(userId, req);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["users"]);
    },
  });

export const useCreateUserInt = (
  queryClient: QueryClient,
  navigate: NavigateFunction
) =>
  useMutation({
    mutationFn: async (props: {
      req: CreateAccountIntReq;
      passwordRepeat: string | undefined;
    }) => {
      const { req, passwordRepeat } = props;
      if (req.password && req.password !== passwordRepeat) {
        throw new Error("Passwörter stimmen nicht überein");
      }
      return await accountIntController.create(req);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(["users"]);
      navigate("/manage-users/" + data.data.id);
    },
  });

export const useDeleteUserInt = (
  queryClient: QueryClient,
  userId: string | undefined,
  navigate: NavigateFunction
) =>
  useMutation({
    mutationFn: async () => {
      if (!userId) throw new Error("keine Nutzer Id gesetz");
      return await accountIntController._delete(userId);
    },
    onSuccess: async () => {
      await queryClient.removeQueries(["users", userId]);
      await queryClient.invalidateQueries(["users"]);
      navigate("/manage-users");
    },
  });

export const useResetPasswordForUserInt = (
  queryClient: QueryClient,
  userId: string | undefined
) =>
  useMutation({
    mutationFn: async () => {
      if (!userId) throw new Error("keine Nutzer Id gesetz");
      return await accountIntController.resetPassword(userId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["users"]);
    },
  });
