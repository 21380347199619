import { EventRspRegistrationStatusEnum } from "../../api/generated";
import styled from "styled-components";

interface EventRegistrationStatusProps {
  registrationStatus?: EventRspRegistrationStatusEnum;
  payed?: boolean;
}

const Wrapper = styled.div``;

export function EventRegistrationStatus(props: EventRegistrationStatusProps) {
  const { registrationStatus, payed } = props;

  if (registrationStatus == null) return <Wrapper>Nicht angemeldet</Wrapper>;

  if (registrationStatus === "ACCEPTED")
    return (
      <Wrapper style={{ color: "green" }}>
        Angemeldet und Akzeptiert {payed && "(gezahlt)"}
      </Wrapper>
    );

  if (registrationStatus === "PENDING")
    return <Wrapper style={{ color: "orange" }}>Warte auf Bestätigung</Wrapper>;

  return <Wrapper style={{ color: "red" }}>Abgelehnt</Wrapper>;
}
