import styled from "styled-components";
import Header from "../../components/ui/layout/header";
import Footer from "../../components/ui/layout/footer";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
`;

const Page = styled.main`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-areas: "header" "text" "footer";
  grid-template-rows: 100px auto 50px;

  .details {
    grid-area: text;
    padding: 2rem;
    background: ${(props) => props.theme.secondaryLightest};
  }

  ul {
    padding-left: 1rem;
  }

  .bold {
    font-weight: bold;
  }

  h1 {
    margin: 0;
  }
  h2,
  h3 {
    margin: 1.75rem 0 1rem 0;
  }

  a {
    text-decoration: none;
    color: #387c49;
  }
`;

export default function ImpressumRoute() {
  return (
    <Wrapper>
      <Page>
        <Header />
        <div className="details">
          <h1>Impressum & Datenschutz</h1>
          <h2>Allgemeines</h2>
          <p>
            Die Kreisjägerschaft Aachen Stadt u. Land e.V. nimmt den Schutz
            Ihrer persönlichen Daten sehr ernst. Wir verarbeiten Ihre
            personenbezogenen Daten im Einklang mit den jeweils anwendbaren
            Datenschutzanforderungen zu den nachfolgend aufgeführten Zwecken.
          </p>
          <p>
            Zur Nutzung dieser Plattform zwecks Kommunikation innerhalb des
            Vorbereitungslehrganges werden personenbezogene Daten im Sinne
            dieser Datenschutz-Information sind sämtliche Informationen, die
            einen Bezug zu Ihrer Person aufweisen. Diese sind:
          </p>
          <ul>
            <li>Voller Name</li>
            <li>Email-Adresse</li>
            <li>Postalische Adresse</li>
            <li>LJV-NRW-Mitgliedsnummer</li>
            <li>Telefonnummer</li>
          </ul>
          <h2>Verantwortliche Stelle</h2>
          <p>
            Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten
            ist die
          </p>

          <p>
            <div className="bold">
              Kreisjägerschaft Aachen Stadt u. Land e.V.
            </div>
            Frohnrather Weg 109
            <br />
            52072 Aachen
            <br />
            Tel.: 0151/54772378
            <br />
            Email: c.stuhlmann@kjs-aachen.de
          </p>
          <h2>Zweck der Verarbeitung und Empfänger</h2>
          <ul>
            <li>Teilnahme an der Plattform</li>
            <li>
              Die Eingabe Ihrer E-Mail-Adresse dient zur Benachrichtigung zu
              Anmeldungen für Veranstaltungen
            </li>
            <li>
              Im Falle wichtiger Veranstaltungsinformationen kann es sein, dass
              wir Sie telefonisch kontaktieren
            </li>
            <li>
              Eine weitere Nutzung Ihrer Daten erfolgt in diesem Rahmen nicht
            </li>
          </ul>
          <h2>Dauer der Speicherung und Löschung Ihrer Daten</h2>
          <p>
            Das Recht zur Speicherung und Nutzung der o.g. personenbezogenen
            Daten erlischt mit der Abmeldung aus der
            Revier-Veranstaltungsplattform oder Ihrer Löschungsmeldung. Bitte
            richten Sie Ihre Löschungsmeldung an:{" "}
            <a href="mailto:fortbildung@kjs-aachen.de">
              fortbildung@kjs-aachen.de
            </a>
          </p>
          <h2>Ihre Rechte</h2>
          <p>Auskunft, Berechtigung, Löschung etc.</p>
          <div className="bold">
            Gerne geben wir Ihnen Auskunft darüber, ob und welche
            personenbezogene Daten von Ihnen bei uns gespeichert sind und an wen
            wir diese ggf. weitergeleitet haben. Nach Maßgabe der gesetzlichen
            Bestimmungen können Sie folgende weitere Rechte geltend machen:
            Berichtigung, Löschung, Einschränkung der Verarbeitung (Sperrung für
            bestimmte Zwecke) sowie Datenübertragung.
          </div>
          <h3>Widerrufsrecht</h3>
          <p>
            Sofern Sie uns eine gesonderte Einwilligung für die Verarbeitung
            Ihrer personenbezogenen Daten erteilt haben, können Sie diese
            jederzeit uns gegenüber widerrufen. Die Rechtmäßigkeit der
            Verarbeitung Ihrer Daten bis zum Widerruf bleibt von einem Widerruf
            ungerührt.
          </p>
          <h3>Fragen oder Beschwerden</h3>
          <p>
            Sie haben das Recht sich bei Fragen oder Beschwerden an die
            zuständige Aufsichtsbehörde, das Landesamt für
            Informationssicherheit und Datenschutz NRW zu wenden
            (www.ldi-nrw.de).
          </p>
        </div>
        <Footer />
      </Page>
    </Wrapper>
  );
}
