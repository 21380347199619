import { useLocation } from "react-router-dom";
import { AdminRoutes, MemberRoutes } from "../routes/route-config";

export const useCurrentPath = () => {
  const routes = MemberRoutes.concat(AdminRoutes);
  const location = useLocation();
  let matchedRoutes = routes.filter(
    (r) => location.pathname != null && location.pathname.startsWith(r.path)
  );

  if (!matchedRoutes || matchedRoutes[0]?.path == null) return "";

  return matchedRoutes[0].path;
};
