import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import Layout from "../../components/ui/layout/layout";
import { BaseType } from "../../types/base-type";

interface ProtectedRouteProps extends BaseType {
  isAllowed: boolean;
  isLoading: boolean;
  redirectTo?: string;
  showLayout?: boolean;
}

function ProtectedRoute(props: ProtectedRouteProps) {
  const {
    isAllowed,
    isLoading,
    redirectTo = "/login",
    children,
    showLayout = true,
  } = props;
  if (isLoading) {
    return <>Authentifiziere...</>;
  }

  if (!isAllowed) {
    return <Navigate to={redirectTo} replace />;
  }

  if (!showLayout) return <> {children ? children : <Outlet />}</>;

  return <Layout>{children ? children : <Outlet />}</Layout>;
}

export default ProtectedRoute;
