import styled from "styled-components";
import React from "react";
import { BaseType } from "../../types/base-type";

const InputWithLabelStyle = styled.div`
  position: relative;
  height: 2rem;

  label {
    position: absolute;
    top: 0;
    left: 0.75em;
    font-size: 1em;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
    color: grey;
  }

  .isError {
    color: ${(props) => props.theme.error};
  }

  input {
    box-shadow: ${(props) => props.theme.boxShadow};
  }

  input,
  label {
    width: 100%;
    height: 100%;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    font-size: 0.9em;
    top: -2.5em;
    left: 0;
    pointer-events: none;
  }
`;

interface InputWithLabelProps extends BaseType {
  label: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  value?: string | number | readonly string[] | undefined;
  required?: boolean | undefined;
  disabled?: boolean;
  ref?:
    | React.RefObject<HTMLInputElement>
    | ((instance: HTMLInputElement | null) => void)
    | null
    | undefined;
  minLength?: number | undefined;
  isError?: boolean;
}

export const InputWithLabel = React.forwardRef<
  HTMLInputElement,
  InputWithLabelProps
>((props, ref) => {
  const {
    className,
    label,
    type,
    onChange,
    value,
    required,
    minLength,
    disabled,
    isError,
    ...rest
  } = props;

  return (
    <InputWithLabelStyle className={className}>
      <StyledInput
        placeholder={" "}
        type={type}
        onChange={onChange}
        value={value}
        required={required}
        ref={ref}
        minLength={minLength}
        disabled={disabled}
        {...rest}
      />
      <label className={isError ? "isError" : ""}>{label}</label>
    </InputWithLabelStyle>
  );
});

export const StyledInput = styled.input`
  height: 2rem;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  min-font-size: 16px;
  line-height: 20px;
  color: #24292e;
  vertical-align: middle;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 8px center;
  border: 1px solid #e1e4e8;
  border-radius: ${(props) => props.theme.mediumBorderRadius};
  outline: none;
  box-shadow: ${(props) => props.theme.boxShadow};

  :focus {
    border-color: ${(props) => props.theme.primary};
    outline: none;
    box-shadow: ${(props) => props.theme.primary1} 0 0 0 2px;
  }

  :disabled {
    background-color: #fafbfc; // Lighter background color for disabled state
    color: #959da5; // Dimmed text color for disabled state
    border: 1px solid #e1e4e8; // Optional: adjust if you want a different border for disabled state
    cursor: not-allowed; // Show a not-allowed cursor on hover
  }
`;

export const StyledFileSelect = styled.input`
  height: auto;
  font-size: 1rem;
  min-font-size: 16px;
  line-height: 20px;
  color: #24292e;
  vertical-align: middle;
  outline: none;
  background: none;
  padding: 0;

  :focus {
    border-color: ${(props) => props.theme.primary};
    outline: none;
    box-shadow: ${(props) => props.theme.primary1} 0 0 0 2px;
  }

  ::file-selector-button {
    height: 2rem;
    outline: 0;
    cursor: pointer;
    padding: 5px 16px;
    font-size: 1rem;
    min-font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    vertical-align: middle;
    border-radius: ${(props) => props.theme.mediumBorderRadius};
    color: #24292e;
    background-color: #fafbfc;
    border: 1px solid #1b1f2326;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0 0,
      rgba(255, 255, 255, 0.25) 0 1px 0 0 inset;
    transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    transition-property: color, background-color, border-color;

    :hover {
      background-color: #f3f4f6;
      border-color: #1b1f2326;
      transition-duration: 0.1s;
    }
  }
`;

export const StyledTextarea = styled.textarea`
  height: 2rem;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  min-font-size: 16px;
  line-height: 20px;
  color: #24292e;
  vertical-align: middle;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 8px center;
  border: 1px solid #e1e4e8;
  border-radius: ${(props) => props.theme.mediumBorderRadius};
  outline: none;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 0 inset;

  :focus {
    border-color: ${(props) => props.theme.primary};
    outline: none;
    box-shadow: ${(props) => props.theme.primary1} 0 0 0 2px;
  }
`;
export const StyledSelect = styled.select`
  height: 2rem;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  min-font-size: 16px;
  line-height: 20px;
  color: #24292e;
  vertical-align: middle;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 8px center;
  border: 1px solid #e1e4e8;
  border-radius: ${(props) => props.theme.mediumBorderRadius};
  outline: none;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 0 inset;

  :focus {
    border-color: ${(props) => props.theme.primary};
    outline: none;
    box-shadow: ${(props) => props.theme.primary1} 0 0 0 2px;
  }
`;
export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  height: 2rem;
  cursor: pointer;
  padding: 5px 16px;
  font-size: 1rem;
  min-font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border-radius: ${(props) => props.theme.mediumBorderRadius};
  color: #24292e;
  background-color: #fafbfc;
  border: 1px solid #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0 0,
    rgba(255, 255, 255, 0.25) 0 1px 0 0 inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;

  :hover {
    background-color: #f3f4f6;
    border-color: #1b1f2326;
    transition-duration: 0.1s;
  }
`;

const ToggleSwitchStyle = styled.input`
  position: relative;
  display: inline-block;
  width: 4rem; // Adjusted for proportionality
  height: 2rem;
  appearance: none; // Removes default browser styles
  background-color: #ccc;
  border-radius: 2rem; // Equal to the height for perfect circles on ends
  cursor: pointer;
  transition: background-color 0.4s;

  margin: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0.1rem; // Slightly smaller than half the height difference for centering
    left: 0.1rem; // Same as top for symmetry
    width: 1.8rem; // Slightly smaller than height for aesthetics
    height: 1.8rem; // Same as width for a perfect circle
    border-radius: 50%;
    background-color: white;
    transition: transform 0.4s;
  }

  &:checked {
    background-color: ${(props) => props.theme.primary1};
  }

  &:checked:before {
    transform: translateX(2rem);
  }
`;

type ToggleSwitchProps = {
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
};

export function ToggleSwitch({ checked, setChecked }: ToggleSwitchProps) {
  const handleChange = () => {
    if (setChecked) {
      setChecked(!checked);
    }
  };

  return (
    <ToggleSwitchStyle
      type="checkbox"
      checked={checked}
      onChange={handleChange}
    />
  );
}

export default ToggleSwitch;
