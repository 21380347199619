import React, { useEffect, useState } from "react";
import { StyledButton } from "./styled-inputs";

export function useIsVisible(ref: React.MutableRefObject<any>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

interface inputWithLabelProps {
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  fetchNextPage: () => any;
}

export const EndlessScrollTrigger = React.forwardRef<
  HTMLButtonElement,
  inputWithLabelProps
>((props: inputWithLabelProps, ref) => {
  const { hasNextPage, isFetchingNextPage, fetchNextPage } = props;
  return (
    <StyledButton
      style={{ display: hasNextPage && !isFetchingNextPage ? "block" : "none" }}
      onClick={() => {
        fetchNextPage();
      }}
      disabled={!hasNextPage || isFetchingNextPage}
      ref={ref}
    >
      {isFetchingNextPage
        ? "Loading..."
        : hasNextPage
        ? "Lade mehr"
        : "Nichts mehr zu laden"}
    </StyledButton>
  );
});
