import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { eventController, eventIntController } from "../api-functions";
import { alertErrorDefault, alertSuccess } from "../../ui/alert";
import { UpdateRegistrationReq } from "../generated";

export const useRegisterForEvent = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async ({ id, comment }: { id: string; comment?: string }) => {
      await eventController.register(id, { comment });
    },
    onSuccess: async () => {
      alertSuccess(`Sie haben sich erfolgreich angemeldet`);
      await queryClient.invalidateQueries(["events"]);
    },
    onError: () => {
      alertErrorDefault();
    },
  });

export const useUnregisterForEvent = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async (id: string) => {
      await eventController.unregister(id);
    },
    onSuccess: async () => {
      alertSuccess(`Sie haben sich erfolgreich abgemeldet`);
      await queryClient.invalidateQueries(["events"]);
    },
    onError: () => {
      alertErrorDefault();
    },
  });

export const useGetPendingRegistrationsInt = (
  page: number,
  searchText: string
) =>
  useQuery({
    queryKey: ["pending-registrations", page, searchText],
    queryFn: async () => {
      return await eventIntController.getAllPendingRegistrations({
        page,
        search: searchText,
      });
    },
  });

export const useGetEventCandidatesInt = (
  eventId: string | undefined,
  page: number
) =>
  useQuery({
    queryKey: ["registrations", "possible", eventId, page],
    queryFn: async () => {
      return eventId != null
        ? await eventIntController.loadEventCandidates(eventId, { page })
        : null;
    },
  });

export const useGetEventRegistrationsInt = (
  eventId: string | undefined,
  page: number
) =>
  useQuery({
    queryKey: ["registrations", eventId, page],
    queryFn: async () => {
      return eventId != null
        ? await eventIntController.getEventParticipants(eventId, { page })
        : null;
    },
    refetchOnWindowFocus: false,
  });

export const useUpdateEventRegistrationInt = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async ({
      registrationId,
      eventId,
      req,
    }: {
      registrationId?: string;
      req: UpdateRegistrationReq;
      eventId?: string;
    }) => {
      if (eventId == null || registrationId == null)
        throw new Error("Keine Event oder Registration Id");
      return await eventIntController.updateRegistration(
        eventId,
        registrationId,
        req
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["registrations"]);
      queryClient.invalidateQueries(["events"]);
      queryClient.invalidateQueries(["pending-registrations"]);
    },
  });

export const useDeleteEventRegistrationInt = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async ({
      registrationId,
      eventId,
    }: {
      registrationId?: string;
      eventId?: string;
    }) => {
      if (eventId == null || registrationId == null)
        throw new Error("Keine Event oder Registration Id");
      return await eventIntController.deleteRegistration(
        eventId,
        registrationId
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["registrations"]);
      queryClient.invalidateQueries(["events"]);
      queryClient.invalidateQueries(["pending-registrations"]);
    },
  });

export const useRegisterForEventInt = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async ({
      accountId,
      eventId,
    }: {
      accountId?: string;
      eventId?: string;
    }) => {
      if (accountId == null || eventId == null) throw new Error();
      return await eventIntController.createEventRegistration(eventId, {
        accountId,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["registrations"]);
      queryClient.invalidateQueries(["events"]);
    },
  });
