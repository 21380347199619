import React from "react";
import Header from "./header";
import Nav from "./nav";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import { BaseType } from "../../../types/base-type";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-areas: "header header" "nav main" "footer footer";
  grid-template-columns: minmax(16rem, 1fr) 5fr;
  grid-template-rows: 6rem auto 3rem;

  @media only screen and (max-width: 600px) {
    grid-template-areas: "header" "main" "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 5rem auto 7.125rem;
    overflow-y: scroll;

    main {
      overflow-y: unset;
      padding: 1rem;
    }

    nav {
      width: 100%;
      height: 4rem;
      padding: 0;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      background-image: linear-gradient(#387c49, #387c49 100%);

      position: fixed;
      bottom: 0;
      grid-area: unset;

      .top-react-icons {
        width: 2rem;
        height: 2rem;
      }

      a {
        padding: 0.25rem;
      }

      a div {
        display: none;
      }

      .desktopLogout,
      .desktopProfile,
      .adminRoutes {
        display: none;
      }

      .memberRoutes {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 0;
        padding-bottom: 0;
        width: 100%;
      }

      .mobileProfile {
        display: initial;
      }
    }

    footer {
      padding-bottom: 6.75rem;
      padding-top: 1rem;
    }
  }
`;

const Main = styled.main`
  grid-area: main;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  padding: 2rem;
  background: ${(props) => props.theme.secondaryLightest};
`;

function Layout(props: BaseType) {
  const { children } = props;
  return (
    <React.Fragment>
      <Wrapper>
        <Header />
        <Nav />
        <Main>
          {children ? children : <Outlet />}
          <div className="spacer" />
        </Main>
        <Footer />
      </Wrapper>
    </React.Fragment>
  );
}

export default Layout;
