import { EventAssetRsp } from "../../../api/generated";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  downloadAsset,
  eventAssetIntController,
} from "../../../api/api-functions";
import { alertDelete, alertLoading } from "../../../ui/alert";
import { BsDownload, BsTrashFill } from "react-icons/bs";

interface EventFileListItemComponentProps {
  asset: EventAssetRsp;
  eventId: string;
  isAdmin: boolean;
}

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .assetName {
    overflow: hidden;
  }

  button {
    display: flex;
    background: none;
    border: none;
    align-items: center;
    width: 2rem;
  }

  .buttonSpace {
    max-width: 5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
`;

export function EventFileListItemComponent(
  props: EventFileListItemComponentProps
) {
  const { asset, eventId, isAdmin } = props;

  const queryClient = useQueryClient();

  const deleteAsset = useMutation({
    mutationFn: async () => {
      await eventAssetIntController.removeAsset(eventId, asset.id!);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["events"]);
    },
  });

  if (!asset || !eventId) return <></>;

  return (
    <Item>
      <div className="assetName">{asset.eventAssetName}</div>
      <div className="buttonSpace">
        {isAdmin && (
          <button onClick={() => alertDelete(deleteAsset.mutateAsync())}>
            <BsTrashFill />
          </button>
        )}
        <button onClick={() => alertLoading(downloadAsset(asset))}>
          <BsDownload />
        </button>
      </div>
    </Item>
  );
}
