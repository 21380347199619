import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../components/ui/layout/header";
import Footer from "../../components/ui/layout/footer";
import {
  InputWithLabel,
  StyledButton,
} from "../../components/ui/styled-inputs";
import { alertLoading } from "../../components/ui/alert";
import { Link } from "react-router-dom";
import { useResetPassword } from "../../components/api/querys/auth-querys";

const ResetPasswordPageStyle = styled.main`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-areas: "header" "content" "footer";
  grid-template-rows: 100px auto 50px;

  h4 {
    margin: 0;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 2rem;
    padding: 4rem;
    background: ${(props) => props.theme.secondaryLightest};
  }

  section {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .link-container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
    color: #387c49;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
`;

function ResetPasswordRoute() {
  const [email, setEmail] = useState("");

  const resetPassword = useResetPassword();

  return (
    <Wrapper>
      <ResetPasswordPageStyle>
        <Header />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            alertLoading(resetPassword.mutateAsync(email));
          }}
        >
          <h4>
            Geben Sie ihre Email ein um ein neues Passwort zugesendet zu
            bekommen
          </h4>
          <section>
            <InputWithLabel
              label="Email"
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <StyledButton type="submit">Zurücksetzen</StyledButton>
          </section>
          <div className="link-container">
            <Link to="/login">Login</Link>
            <Link to="/register">Registrieren</Link>
          </div>
        </form>
        <Footer />
      </ResetPasswordPageStyle>
    </Wrapper>
  );
}

export default ResetPasswordRoute;
