import React from "react";
import styled from "styled-components";
import {
  InputWithLabel,
  StyledButton,
  StyledInput,
  StyledSelect,
} from "../../ui/styled-inputs";
import { BaseType } from "../../../types/base-type";
import {
  FieldValues,
  FormState,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";

const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;

  section {
    max-width: 40rem;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    h1 {
      align-self: start;
    }
  }

  form {
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 4rem;
  }

  h1 {
    margin: 0;
  }

  div,
  button {
    width: 100%;
    height: 3rem;
  }

  .textSpace {
    max-width: 50rem;
    text-align: center;
    display: flex;
    align-items: center;

    h1 {
      align-self: center;
    }
  }

  .vertical {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  .bigger {
    width: 190%;
  }

  a {
    text-decoration: none;
    color: #387c49;
  }
`;

interface ProfileFormProps extends BaseType {
  onSubmit: (d: any) => any;
  register: UseFormRegister<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  submitButtonText: string;
  textSpace?: React.ReactNode;
  buttonSpace?: React.ReactNode;
  type: "REGISTER" | "PROFILE" | "EDIT_USER" | "CREATE_USER";
  formState?: FormState<FieldValues>;
}

export function ProfileFormComponent({
  register,
  onSubmit,
  handleSubmit,
  submitButtonText,
  textSpace,
  buttonSpace,
  type,
  formState,
}: ProfileFormProps) {
  let isRegisterOrProfile = type === "PROFILE" || type === "REGISTER";
  let isRegister = type === "REGISTER";

  return (
    <ProfileForm onSubmit={handleSubmit((d) => onSubmit(d))}>
      {textSpace && <section className="textSpace">{textSpace}</section>}
      <section className="generalInformationSpace">
        <h1>Allgemeine Informationen</h1>
        <InputWithLabel
          label="Vorname"
          isError={formState?.errors.firstName != null}
          {...register("firstName", { required: true })}
        />
        <InputWithLabel
          label="Nachname"
          isError={formState?.errors.lastName != null}
          {...register("lastName", { required: true })}
        />
        <InputWithLabel
          label="Email"
          type="email"
          disabled={type === "PROFILE"}
          isError={formState?.errors.email != null}
          {...register("email", { required: true })}
        />
        <div className="vertical">
          <InputWithLabel
            label="Telefonnummer"
            isError={formState?.errors.phoneNumber != null}
            {...register("phoneNumber", { required: isRegisterOrProfile })}
          />
          <InputWithLabel
            label="LJV-Nr."
            disabled={type === "PROFILE"}
            isError={formState?.errors.ljvNumber != null}
            {...register("ljvNumber", { required: isRegister })}
          />
        </div>
      </section>
      <section className="addressSpace">
        <h1>Adresse</h1>
        <div className="vertical">
          <InputWithLabel
            className="bigger"
            label="Straße"
            isError={formState?.errors.street != null}
            {...register("street", { required: isRegisterOrProfile })}
          />
          <InputWithLabel
            className="smaller"
            label="Haus Nr."
            isError={formState?.errors.houseNumber != null}
            {...register("houseNumber", { required: isRegisterOrProfile })}
          />
        </div>
        <div className="vertical">
          <InputWithLabel
            className="bigger"
            label="Stadt"
            isError={formState?.errors.city != null}
            {...register("city", { required: isRegisterOrProfile })}
          />
          <InputWithLabel
            className="smaller"
            label="PLZ"
            isError={formState?.errors.postalCode != null}
            {...register("postalCode", { required: isRegisterOrProfile })}
          />
        </div>
      </section>
      <section className="passwordSpace">
        <h1>Passwort</h1>
        <InputWithLabel
          label="Password"
          type="password"
          isError={formState?.errors.password != null}
          {...register("password", {
            required: type === "REGISTER" || type === "CREATE_USER",
            minLength: 8,
          })}
        />

        <InputWithLabel
          label="Wiederhole Password"
          type="password"
          isError={formState?.errors.passwordRepeat != null}
          {...register("passwordRepeat", {
            required: type === "REGISTER",
            minLength: 8,
          })}
        />
      </section>
      {(type === "EDIT_USER" || type === "CREATE_USER") && (
        <section>
          <h1>System Einstellungen</h1>
          <div className="vertical">
            <label>Aktiviert:</label>
            <StyledInput
              type="checkbox"
              className="checkbox"
              style={{ height: "1.5rem", width: "1.5rem" }}
              {...register("approved", { required: false })}
            />
          </div>
          <div className="vertical">
            <label>Email bestätigt:</label>
            <StyledInput
              type="checkbox"
              className="checkbox"
              style={{ height: "1.5rem", width: "1.5rem" }}
              {...register("emailVerified", { required: false })}
            />
          </div>
          <div className="vertical">
            <label>Rolle:</label>
            <StyledSelect {...register("role", { required: true })}>
              <option value="MEMBER">Member</option>
              <option value="ADMIN">Admin</option>
            </StyledSelect>
          </div>
        </section>
      )}
      <section>
        <StyledButton type="submit">{submitButtonText}</StyledButton>
        {buttonSpace && buttonSpace}
      </section>
    </ProfileForm>
  );
}
