import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { accountController, registerController } from "../api-functions";
import { AccountMeRsp, RegisterAccountReq } from "../generated";
import { alertError } from "../../ui/alert";

export const useResetPassword = () =>
  useMutation({
    mutationFn: async (email: string) =>
      await registerController.resetPassword1(email),
  });

export const useRegister = () =>
  useMutation({
    mutationFn: async (user: RegisterAccountReq) =>
      await registerController.registerAccount(user),
  });

export const useLogin = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      let user: AccountMeRsp;
      try {
        const response = await accountController.getPrincipal({
          auth: {
            username: email,
            password: password,
          },
        });

        user = response.data;
      } catch (e) {
        throw e;
      }
      return user;
    },
    onSuccess: async (data) => {
      await queryClient.setQueriesData(["me"], data);
      queryClient.invalidateQueries(["me"]);
    },
    onError: (e) => {
      if (e instanceof AxiosError) {
        if (e.response?.status === 401) {
          alertError(`Password oder Email nicht korrekt`);
        } else {
          alertError(`Etwas hat nicht funktioniert: ` + e.message);
        }
      } else {
        alertError(`Etwas hat nicht funktioniert`);
      }
    },
  });

export const useLogout = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async () => {
      await axios.post("/backend/logout");
      localStorage.clear();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["me"]);
      queryClient.setQueriesData(["me"], {});
      queryClient.removeQueries([]);
    },
  });

export const useImpersonateInt = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: (email: string) => {
      return axios.post("/backend/login/impersonate", null, {
        params: { username: email },
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([]);
    },
  });

export const useUnImpersonateInt = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: () => {
      return axios.post("/backend/logout/impersonate");
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([]);
    },
  });

export const useGetMe = () =>
  useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      let res: AccountMeRsp;
      try {
        res = (await accountController.getPrincipal()).data;
      } catch (e) {
        res = {};
      }
      return res;
    },
    refetchOnWindowFocus: true,
    retry: 0,
    staleTime: 500,
  });
