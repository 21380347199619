import React from "react";
import styled from "styled-components";

export interface PaginierungProps {
  page: number;
  setPage: (page: number) => any;
  totalPages?: number;
  totalElements?: number;
}

const PaginierungStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    padding-left: 1rem;
  }

  button {
    display: flex;
    justify-content: center;
    outline: 0;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    padding: 5px 16px;
    font-size: 1rem;
    min-font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    vertical-align: middle;
    border-radius: ${(props) => props.theme.mediumBorderRadius};
    color: #24292e;
    background-color: #fafbfc;
    border: 1px solid #1b1f2326;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0 0,
      rgba(255, 255, 255, 0.25) 0 1px 0 0 inset;
    transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    transition-property: color, background-color, border-color;

    :hover {
      background-color: #f3f4f6;
      border-color: #1b1f2326;
      transition-duration: 0.1s;
    }

    :disabled {
      background-color: #e7e6e6;
    }
  }
`;

function Paginierung(props: PaginierungProps) {
  const { page, setPage, totalPages = 0, totalElements } = props;
  function setPageIntern(page: number) {
    if (totalPages === 0) {
      setPage(0);
    } else if (page <= 0) {
      setPage(0);
    } else if (page >= totalPages) {
      setPage(totalPages - 1);
    } else {
      setPage(page);
    }
  }

  return (
    <PaginierungStyle>
      <button onClick={() => setPageIntern(0)}>1</button>
      <button onClick={() => setPageIntern(page - 1)}>«</button>
      {page == totalPages - 1 && totalPages > 2 && (
        <button onClick={() => setPageIntern(page - 2)}>{page - 1}</button>
      )}
      {page > 0 && (
        <button onClick={() => setPageIntern(page - 1)}>{page}</button>
      )}
      <button disabled>{page + 1}</button>
      {page < totalPages - 1 && (
        <button onClick={() => setPageIntern(page + 1)}>{page + 2}</button>
      )}
      {page <= 0 && totalPages > 2 && (
        <button onClick={() => setPageIntern(page + 2)}>{page + 3}</button>
      )}
      <button onClick={() => setPageIntern(page + 1)}>»</button>
      <button onClick={() => setPageIntern(totalPages)}>
        {totalPages === 0 ? 1 : totalPages}
      </button>
      <div>Insgesamt: {totalElements}</div>
    </PaginierungStyle>
  );
}

export default Paginierung;
