import { EventCard } from "../../components/domain/event/event-card";
import React, { useEffect, useRef, useState } from "react";
import { PageWrapper } from "../../components/ui/page-wrapper";
import {
  EndlessScrollTrigger,
  useIsVisible,
} from "../../components/ui/endless-scroller";
import { BreadCrumbsProps } from "../../components/ui/layout/bread-crumbs";
import { LoadingSpinner } from "../../components/ui/loading-spinner";
import { CardGrid } from "../../components/ui/card-grid";
import { useEndlessEvents } from "../../components/api/querys/event-querys";
import ToggleSwitch from "../../components/ui/styled-inputs";

const navPathLinks: BreadCrumbsProps[] = [
  { text: "Anstehende Veranstaltungen" },
];

export default function EventsRoute() {
  const [searchText, setSearchtext] = useState("");
  const [filterPastEvents, setFilterPastEvents] = useState(false);

  //needed for endless scroller
  const loadMoreRef = useRef<HTMLButtonElement>(null);
  let isVisible = useIsVisible(loadMoreRef);
  useEffect(() => {
    if (isVisible) {
      fetchNextPage();
    }
  }, [isVisible]);

  const additionalInputs = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        justifyContent: "space-between",
      }}
    >
      <label>Abgelaufene Events einblenden</label>
      <ToggleSwitch
        checked={filterPastEvents}
        setChecked={setFilterPastEvents}
      />
    </div>
  );

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useEndlessEvents(searchText, !filterPastEvents);

  return (
    <PageWrapper
      links={navPathLinks}
      pageTitle="Anstehende Veranstaltungen"
      searchText={searchText}
      setSearchText={setSearchtext}
      additionalInputs={additionalInputs}
    >
      {data?.pages && data.pages.at(0)?.data.totalPages === 0 && (
        <>
          <h2 style={{ width: "100%" }}>
            Keine Veranstaltungen gefunden. Um alte Veranstaltungen zu sehen
            legen Sie bitte den Schalter um.
          </h2>
        </>
      )}
      <CardGrid>
        {!data?.pages && <LoadingSpinner />}
        {data?.pages.map((page, i) => (
          <React.Fragment key={i}>
            {page.data.elements?.map((event, index) => (
              <EventCard key={index} event={event}></EventCard>
            ))}
          </React.Fragment>
        ))}
        <EndlessScrollTrigger
          ref={loadMoreRef}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
        />
      </CardGrid>
    </PageWrapper>
  );
}
