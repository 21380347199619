import React from "react";
import styled from "styled-components";
import { EventRegistrationIntRsp } from "../../../api/generated";
import { BaseType } from "../../../../types/base-type";
import {
  BsCheckCircle,
  BsQuestionCircle,
  BsTrash,
  BsXCircle,
} from "react-icons/bs";
import { StyledButton, StyledInput } from "../../../ui/styled-inputs";
import { useQueryClient } from "@tanstack/react-query";
import { alertDelete, alertSave } from "../../../ui/alert";
import {
  useDeleteEventRegistrationInt,
  useUpdateEventRegistrationInt,
} from "../../../api/querys/event-registration-querys";

interface EditRegistrationsListItemComponentProps extends BaseType {
  registration?: EventRegistrationIntRsp;
}

function EditRegistrationsListItemComponentStruct(
  props: EditRegistrationsListItemComponentProps
) {
  const { className, registration } = props;

  const queryClient = useQueryClient();

  const updateMutation = useUpdateEventRegistrationInt(queryClient);
  const deleteMutation = useDeleteEventRegistrationInt(queryClient);

  return (
    <div className={className}>
      <div>
        {registration ? (
          registration.participant?.firstName +
          " " +
          registration.participant?.lastName
        ) : (
          <h4>Name</h4>
        )}
      </div>
      <div>
        {registration ? (
          <StyledInput
            type="checkbox"
            checked={registration.payed || false}
            className="checkbox"
            onChange={() => {
              alertSave(
                updateMutation.mutateAsync({
                  registrationId: registration?.id,
                  eventId: registration?.eventId,
                  req: { payed: !registration?.payed },
                })
              );
            }}
          />
        ) : (
          <h4>Gezahlt</h4>
        )}
      </div>
      <div>
        {registration ? (
          registration.status === "PENDING" ? (
            <BsQuestionCircle color="orange" />
          ) : registration.status === "ACCEPTED" ? (
            <BsCheckCircle color="green" />
          ) : (
            <BsXCircle color="red" />
          )
        ) : (
          <h4>Akzeptiert</h4>
        )}
      </div>
      <div>
        {registration ? (
          <>
            {registration.status != "ACCEPTED" && (
              <StyledButton
                onClick={() => {
                  alertSave(
                    updateMutation.mutateAsync({
                      registrationId: registration?.id,
                      eventId: registration?.eventId,
                      req: { status: "ACCEPTED" },
                    })
                  );
                }}
              >
                <BsCheckCircle color="green" />
              </StyledButton>
            )}
            {registration.status != "REJECTED" && (
              <StyledButton
                onClick={() => {
                  alertSave(
                    updateMutation.mutateAsync({
                      registrationId: registration?.id,
                      eventId: registration?.eventId,
                      req: { status: "REJECTED" },
                    })
                  );
                }}
              >
                <BsXCircle color="red" />
              </StyledButton>
            )}
            {registration.status != "PENDING" && (
              <StyledButton
                onClick={() => {
                  alertSave(
                    updateMutation.mutateAsync({
                      registrationId: registration?.id,
                      eventId: registration?.eventId,
                      req: { status: "PENDING" },
                    })
                  );
                }}
              >
                <BsQuestionCircle color="orange" />
              </StyledButton>
            )}
          </>
        ) : (
          <h4>Bearbeiten</h4>
        )}
      </div>
      <div>
        {registration ? (
          <>
            <StyledButton
              onClick={() => {
                alertDelete(
                  deleteMutation.mutateAsync({
                    registrationId: registration?.id,
                    eventId: registration?.eventId,
                  })
                );
              }}
            >
              <BsTrash color="red" />
            </StyledButton>
          </>
        ) : (
          <h4>Löschen</h4>
        )}
      </div>
    </div>
  );
}

const EditRegistrationsListItemComponent = styled(
  EditRegistrationsListItemComponentStruct
)`
  background-color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  justify-content: space-between;
  min-height: 2rem;
  width: 100%;
  border-radius: ${(props) => props.theme.mediumBorderRadius};

  h4 {
    margin: 0;
  }

  div:nth-child(1) {
    width: 10rem;
  }

  div:nth-child(2) {
    width: 5rem;
    display: flex;
    justify-content: center;
  }

  div:nth-child(3) {
    width: 5rem;
    display: flex;
    justify-content: center;
  }

  div:nth-child(4) {
    width: 8rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  .checkbox {
    accent-color: ${(props) => props.theme.primary};
    width: 1rem;
    height: 1rem;
  }
`;

export default EditRegistrationsListItemComponent;
