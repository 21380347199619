import { IconType } from "react-icons";
import {
  BsCheckSquareFill,
  BsFillCalendarDateFill,
  BsPencilFill,
  BsPersonCircle,
  BsQuestionCircle,
} from "react-icons/bs";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFoundRoute from "./util-routes/not-found-route";
import LoginRoute from "./public/login-route";
import RegisterRoute from "./public/register-route";
import ImpressumRoute from "./public/impressum-route";
import ProtectedRoute from "./util-routes/protected-route";
import EventsRoute from "./member/events-route";
import EventDetailsRoute from "./member/event-details-route";
import ManageUsersRoute from "./admin/manage-users-route";
import React from "react";
import { FiUsers } from "react-icons/fi";
import RegisterConfirmationRoute from "./public/register-confirmation-route";
import ManageEventsRoute from "./admin/manage-events-route";
import MyEventsRoute from "./member/my-events-route";
import ResetPasswordRoute from "./public/reset-password-route";
import ManageRegistrationsRoute from "./admin/manage-registrations-route";
import { useGetMe } from "../components/api/querys/auth-querys";
import ProfileRoute from "./member/profile-route";
import { EditEventsRoute } from "./admin/edit-events-route";
import { EditUsersRoute } from "./admin/edit-users-route";

export interface RouteProps {
  name: string;
  path: string;
  icon: IconType;
}

//defines routes shown to MEMBERS in the navbar
export const MemberRoutes: RouteProps[] = [
  { name: "Veranstaltungen", path: "/events", icon: BsFillCalendarDateFill },
  { name: "Meine Anmeldungen", path: "/myevents", icon: BsCheckSquareFill },
  { name: "Profile", path: "/profile", icon: BsPersonCircle },
];

//defines routes shown to ADMINS in the navbar
export const AdminRoutes: RouteProps[] = [
  {
    name: "Offene Anmeldungen",
    path: "/manage-registrations",
    icon: BsQuestionCircle,
  },
  { name: "Nutzer verwalten", path: "/manage-users", icon: FiUsers },
  {
    name: "Veranstaltungen verwalten",
    path: "/manage-events",
    icon: BsPencilFill,
  },
];

export function RevierRouter() {
  //get authentication data
  const { data, isLoading, isError } = useGetMe();

  const user = data;
  const memberIsAllowed = !!user && !isError && user.id != null;
  const adminIsAllowed = memberIsAllowed && user.role === "ADMIN";

  return (
    <Routes>
      {/*Public routes*/}
      <Route path="*" element={<NotFoundRoute />} />
      <Route path="impressum" element={<ImpressumRoute />} />
      <Route path="register/confirm" element={<RegisterConfirmationRoute />} />
      <Route path="resetPassword" element={<ResetPasswordRoute />} />
      <Route
        path="/"
        element={
          <ProtectedRoute
            isAllowed={!memberIsAllowed}
            isLoading={isLoading}
            redirectTo={adminIsAllowed ? "/manage-events" : "/events"}
            showLayout={false}
          />
        }
      >
        {/*Routes are not available when loggedin*/}
        <Route path="login" element={<LoginRoute />} />
        <Route path="register" element={<RegisterRoute />} />
      </Route>
      <Route
        path="/"
        element={
          <ProtectedRoute isAllowed={memberIsAllowed} isLoading={isLoading} />
        }
      >
        {/*Routes members and admins can access*/}
        <Route index element={<Navigate to="/events" />} />
        <Route path="events" element={<EventsRoute />} />
        <Route path="myevents" element={<MyEventsRoute />} />
        <Route path="profile" element={<ProfileRoute />} />
        <Route path="events/:id" element={<EventDetailsRoute />} />
      </Route>
      <Route
        path="/"
        element={
          <ProtectedRoute
            isAllowed={adminIsAllowed}
            isLoading={isLoading}
            redirectTo={"/events"}
          />
        }
      >
        {/*Routes admins can access*/}
        <Route path="manage-users" element={<ManageUsersRoute />} />
        <Route path="manage-users/:id" element={<EditUsersRoute />} />
        <Route path="manage-events" element={<ManageEventsRoute />} />
        <Route path="manage-events/:id" element={<EditEventsRoute />} />
        <Route
          path="manage-registrations"
          element={<ManageRegistrationsRoute />}
        />
      </Route>
    </Routes>
  );
}
