import { PageWrapper } from "../../components/ui/page-wrapper";
import { Card } from "../../components/ui/card";
import EditEventDetailsTab from "../../components/domain/event/admin/edit-event-details-tab";
import EditEventRegistrationsTab from "../../components/domain/event/admin/edit-event-registration-tab";
import EditEventAddRegistrationsTab from "../../components/domain/event/admin/edit-event-add-registration-tab";
import EditEventUploadFilesTab from "../../components/domain/event/admin/edit-event-upload-files-tab";
import Accordion from "../../components/ui/accordion";
import React from "react";
import { useParams } from "react-router-dom";
import { useIntGetEventById } from "../../components/api/querys/event-querys";

export function EditEventsRoute() {
  const { id } = useParams();

  const { data } = useIntGetEventById(id!, false, id != "new");

  const pageTitel =
    id === "new" ? "Neue Veranstaltung anlegen" : data?.data.title || "";

  return (
    <PageWrapper
      links={[
        { text: "Veranstaltungen verwalten", link: "/manage-events" },
        { text: pageTitel },
      ]}
      pageTitle={pageTitel}
    >
      <Card>
        <Accordion
          openDefault="Veranstaltung bearbeiten"
          pages={[
            {
              title: "Veranstaltung bearbeiten",
              children: <EditEventDetailsTab eventId={id} />,
            },
            {
              title: "Anmeldungen verwalten",
              children: <EditEventRegistrationsTab eventId={id} />,
              display: id != "new",
            },
            {
              title: "Nutzer registrieren",
              children: <EditEventAddRegistrationsTab eventId={id} />,
              display: id != "new",
            },
            {
              title: "Dokumente hochladen",
              children: <EditEventUploadFilesTab eventId={id} />,
              display: id != "new",
            },
          ]}
        ></Accordion>
      </Card>
    </PageWrapper>
  );
}
