import styled from "styled-components";
import { BaseType } from "../../types/base-type";
import { EventAssetRsp } from "../api/generated";
import React from "react";

interface ImageCarouselProps extends BaseType {
  images?: EventAssetRsp[];
}
function ImageCarouselStruct(props: ImageCarouselProps) {
  const { className, images } = props;
  return (
    <div className={className}>
      <div className="slides">
        {images?.map((image, key) => {
          return (
            <img
              id={"slide" + key}
              src={"/backend/asset/" + image.id + "/download"}
              alt={image.fileName}
              key={key}
            />
          );
        })}
      </div>
      <div className="links">
        {images?.map((image, key) => {
          return (
            <a href={"#slide" + key} key={key}>
              {key + 1}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export const ImageCarousel = styled(ImageCarouselStruct)`
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;
  display: grid;
  box-shadow: ${(props) => props.theme.boxShadow};

  border-radius: 3px;

  .slides {
    display: flex;
    column-gap: 1rem;
    width: 100%;
    grid-row: 1;

    overflow-x: auto;
    scroll-snap-type: x mandatory;

    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */

  .slides::-webkit-scrollbar {
    display: none;
  }

  .slides > img {
    scroll-snap-align: start;
    flex-shrink: 0;
    object-fit: cover;
    width: 100%;
    background: #eee;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
  }

  .links {
    width: 100%;
    position: absolute;
    grid-row: 1;
    align-self: end;
    bottom: 0.5rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    z-index: 1;
  }

  a {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background: ${(props) => props.theme.secondary};
    color: black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
