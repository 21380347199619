import React from "react";
import logo from "../../../images/logo.svg";

import styled from "styled-components";
import { StyledButton } from "../styled-inputs";
import { useQueryClient } from "@tanstack/react-query";
import { alertLoading } from "../alert";
import { useGetMe, useUnImpersonateInt } from "../../api/querys/auth-querys";

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  grid-area: header;
  padding: 0 1rem;
  height: 100%;
  width: 100%;

  @media only screen and (max-width: 600px) {
    h1 {
      display: none;
    }
  }

  img {
    height: 5rem;
  }

  section {
    color: orange;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

const Header = () => {
  const { data } = useGetMe();
  const queryClient = useQueryClient();
  const unImpersonate = useUnImpersonateInt(queryClient);

  return (
    <StyledHeader>
      <img src={logo} alt="kjs Logo" />
      {data?.impersonated && (
        <section>
          Eingeloggt als {data.firstName} {data.lastName}
          <StyledButton
            onClick={() => {
              alertLoading(unImpersonate.mutateAsync());
            }}
          >
            Ausloggen
          </StyledButton>
        </section>
      )}
      <h1>KJS-Revier</h1>
    </StyledHeader>
  );
};
export default Header;
