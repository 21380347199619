import {ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState,} from "draft-js";
import draftToHtml from "draftjs-to-html";

//Converts the HTML string | JSON representation to a DraftJS EditorState
export function convertHtmlToDraft(html: string | undefined): EditorState {
  try {
    if (html?.startsWith("{")) {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(html || ""))
      );
    } else {
      const blocksFromHTML = convertFromHTML(html || "");
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(state);
    }
  } catch {
    return EditorState.createEmpty();
  }
}

//Converts the DraftJS EditorState to an HTML string
export function convertDraftToHtml(editorState: EditorState): string {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
}
