import React, { useState } from "react";
import styled from "styled-components";
import { BaseType } from "../../../../types/base-type";
import { addFilesToEvent } from "../../../api/api-functions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StyledButton, StyledFileSelect } from "../../../ui/styled-inputs";
import { alertSave } from "../../../ui/alert";
import { EventFileListItemComponent } from "./event-file-list-item-component";
import { useIntGetEventById } from "../../../api/querys/event-querys";

interface EditEventUploadFilesTabProps extends BaseType {
  eventId?: string;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;

  button {
    width: 100%;
  }

  input::file-selector-button {
  }

  .buttonSpace {
    display: flex;
    gap: 1rem;
  }

  .fileList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  h4 {
    margin: 0;
  }
`;

function EditEventUploadFilesTab(props: EditEventUploadFilesTabProps) {
  const { eventId } = props;
  const [selectedFiles, setSelectedFiles] = useState<FileList | undefined>();
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const { data } = useIntGetEventById(eventId!, false);

  const queryClient = useQueryClient();

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target == null || e.target.files == null || e.target.files[0] == null)
      return;
    setSelectedFiles(e.target.files);
    setIsSelected(true);
  };

  const upload = useMutation({
    mutationFn: async (isMainPicture: boolean) => {
      if (eventId == null) throw new Error("Keine EventId");
      if (selectedFiles === undefined)
        throw new Error("Kein Dokument ausgewählt");

      return await addFilesToEvent(
        selectedFiles,
        eventId,
        isMainPicture ? "MAIN_PICTURE" : "MATERIAL"
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["events"]);
    },
  });

  if (!eventId) return <StyledContent></StyledContent>;

  return (
    <StyledContent>
      <div className="fileList">
        <h4>Hauptbild:</h4>
        {data?.data.assets
          ?.filter((asset) => {
            return asset.eventAssetType === "MAIN_PICTURE";
          })
          .map((asset, key) => {
            return (
              <EventFileListItemComponent
                key={key}
                asset={asset}
                eventId={eventId}
                isAdmin={true}
              />
            );
          })}
        <h4>Anhänge:</h4>
        {data?.data.assets
          ?.filter((asset) => {
            return asset.eventAssetType === "MATERIAL";
          })
          .map((asset, key) => {
            return (
              <EventFileListItemComponent
                key={key}
                asset={asset}
                eventId={eventId}
                isAdmin={true}
              />
            );
          })}
      </div>
      <StyledFileSelect
        multiple={true}
        type="file"
        name="file"
        onAbort={changeHandler}
        onChange={changeHandler}
      />
      <div className="buttonSpace">
        {isSelected && (
          <>
            <StyledButton
              disabled={!isSelected}
              onClick={() => alertSave(upload.mutateAsync(true))}
            >
              Hochladen als Hauptbild
            </StyledButton>
            <StyledButton
              disabled={!isSelected}
              onClick={() => alertSave(upload.mutateAsync(false))}
            >
              Hochladen als Anhang
            </StyledButton>
          </>
        )}
      </div>
    </StyledContent>
  );
}

export default EditEventUploadFilesTab;
