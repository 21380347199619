import { createGlobalStyle } from "styled-components";

export const theme = {
  primaryDarker2: "#085E33",
  primaryDarker1: "#09713D",
  primary: "#0a7f44",
  primary1: "#359B5E",
  primary2: "#55B979",
  primary3: "#73D794",
  primary4: "#92F5B1",

  secondaryLightest: "#ddeae3",
  secondaryLight: "#B5C8C3",
  secondary: "#8FA7A6",
  secondaryDark: "#6B868B",
  secondaryDarkest: "#4B6771",

  black: "#020402",
  white: "#FFFFFF",

  warn: "#FF934F",
  error: "#F71735",
  info: "#73D794",

  smallFontSize: "0.875rem",

  smallBorderRadius: "2px",
  mediumBorderRadius: "6px",
  bigBorderRadius: "8px",

  space1: "1px",
  space4: "0.25rem",
  space8: "0.5rem",
  space12: "0.75rem",
  space16: "1rem",
  space24: "1.5rem",
  space32: "2rem",
  space64: "4rem",
  space72: "4.5rem",
  space128: "7.875rem",

  boxShadow:
    "rgba(27, 31, 35, 0.04) 0 1px 0 0,  rgba(255, 255, 255, 0.25) 0 1px 0 0 inset",
};

const GlobalStyles = createGlobalStyle`
    
    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-weight: bolder;
    }

    h3 {
      font-size: 1.625rem;
      line-height: 2.5rem;
      font-weight: bolder;
    }

    h4 {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: bold;
    }
    
    body {
      font-size: 1rem;
    }
    
`;

export default GlobalStyles;
