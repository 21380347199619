import { toast } from "react-hot-toast";

export const alertSave = async (promise: Promise<unknown>) => {
  return await toast.promise(promise, {
    loading: "Speichern..",
    success: "Gespeichert!",
    error: (e: Error) =>
      e.message ? e.message : "Beim Speichern ist ein Fehler aufgetreten",
  });
};

export const alertDelete = async (promise: Promise<unknown>) => {
  return await toast.promise(promise, {
    loading: "Löschen..",
    success: "Gelöscht!",
    error: (e: Error) =>
      e.message ? e.message : "Beim Löschen ist ein Fehler aufgetreten",
  });
};

export const alertLoading = async (promise: Promise<unknown>) => {
  return await toast.promise(promise, {
    loading: "laden..",
    success: "fertig!",
    error: (e: Error) =>
      e.message ? e.message : "Beim Laden ist ein Fehler aufgetreten",
  });
};

export const alertErrorDefault = () => {
  toast.error("Das hat nicht funktioniert");
};

export const alertError = (msg: string) => toast.error(msg);
export const alertSuccess = (msg: string) => toast.success(msg);
