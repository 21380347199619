import React, { useState } from "react";
import styled from "styled-components";
import { StyledButton } from "./styled-inputs";
import { BaseType } from "../../types/base-type";
import { BsDashLg, BsPlusLg } from "react-icons/bs";

interface AccordionPageProps extends BaseType {
  title: string;
  display?: boolean;
}

interface AccordionProps {
  pages: AccordionPageProps[];
  openDefault?: string;
}

const AccordionStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .openButton {
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .tabSpace {
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .tabSpace::-webkit-scrollbar {
    display: none;
  }
`;

function Accordion({ pages, openDefault }: AccordionProps) {
  const [open, setOpen] = useState<string | undefined>(openDefault);

  return (
    <AccordionStyle>
      {pages.map((page, key) => {
        const { display = true } = page;
        return (
          <div key={key}>
            {display && (
              <>
                <StyledButton
                  onClick={() => {
                    open === page.title
                      ? setOpen(undefined)
                      : setOpen(page.title);
                  }}
                  className="openButton"
                >
                  <h2>{page.title}</h2>
                  {open !== page.title ? <BsPlusLg /> : <BsDashLg />}
                </StyledButton>
                <div
                  className="tabSpace"
                  style={{
                    maxHeight: open != page.title ? "0" : "100rem",
                    padding: open != page.title ? "0 1rem 0 1rem" : "1rem",
                  }}
                >
                  {page.children}
                </div>
              </>
            )}
          </div>
        );
      })}
    </AccordionStyle>
  );
}

export default Accordion;
