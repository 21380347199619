import React, { useState } from "react";
import { PageWrapper } from "../../components/ui/page-wrapper";
import { Outlet } from "react-router-dom";
import EventListItemComponent from "../../components/domain/event/admin/event-list-item-component";
import { BreadCrumbsProps } from "../../components/ui/layout/bread-crumbs";
import { BaseType } from "../../types/base-type";
import { LoadingSpinner } from "../../components/ui/loading-spinner";
import { useGetEvents } from "../../components/api/querys/event-querys";
import { ListWrapper } from "../../components/ui/list-wrapper";
import ToggleSwitch from "../../components/ui/styled-inputs";

const navPathLinks: BreadCrumbsProps[] = [
  { text: "Veranstaltungen verwalten" },
];

function ManageEventsRoute(props: BaseType) {
  const { children } = props;
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [filterPastEvents, setFilterPastEvents] = useState(false);

  const { data, isLoading } = useGetEvents(
    { page, search: searchText },
    !filterPastEvents
  );

  const additionalInputs = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        justifyContent: "space-between",
      }}
    >
      <label>Abgelaufene Events einblenden</label>
      <ToggleSwitch
        checked={filterPastEvents}
        setChecked={setFilterPastEvents}
      />
    </div>
  );

  return (
    <PageWrapper
      links={navPathLinks}
      pageTitle="Veranstaltungen verwalten"
      searchText={searchText}
      setSearchText={setSearchText}
      pagination={{
        page,
        setPage,
        totalPages: data?.data.totalPages,
        totalElements: data?.data.totalElements,
      }}
      hasCreateButton={true}
      additionalInputs={additionalInputs}
    >
      <ListWrapper>
        <EventListItemComponent />
        {isLoading && <LoadingSpinner />}
        {data?.data?.elements?.map((event, i) => (
          <EventListItemComponent key={i} event={event} />
        ))}
      </ListWrapper>
      {children ? children : <Outlet />}
    </PageWrapper>
  );
}

export default ManageEventsRoute;
