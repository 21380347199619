import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQueryClient } from "@tanstack/react-query";
import { StyledButton, StyledInput } from "../../../ui/styled-inputs";
import { alertDelete, alertSave } from "../../../ui/alert";
import { CreateEventIntReq, EventIntRsp } from "../../../api/generated";
import { BaseType } from "../../../../types/base-type";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  useCreateEventInt,
  useDeleteEventInt,
  useIntGetEventById,
  useIntUpdateEvent,
} from "../../../api/querys/event-querys";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  convertDraftToHtml,
  convertHtmlToDraft,
} from "../../../../utils/draftJsUtils";

interface EditEventDetailsTabProps extends BaseType {
  eventId: string | undefined;
}

const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;

  column-gap: 5%;
  row-gap: 1rem;

  label {
    width: 35%;
  }

  button {
    width: 100%;
  }

  .spacer {
    border-bottom: solid 1px ${(props) => props.theme.secondaryDarkest};
    width: 100%;
  }

  input,
  select,
  textarea,
  .editor {
    width: 60%;
  }

  .editor {
    border: lightgray solid 1px;
  }

  .checkbox {
    accent-color: ${(props) => props.theme.primary};
    margin: 0 30% 0 0;
  }
`;

function EditEventDetailsTab(props: EditEventDetailsTabProps) {
  const { eventId } = props;

  const { register, handleSubmit, setValue, watch } = useForm();

  const [descriptionEditorState, setDescriptionEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [emailEditorState, setEmailEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data, isError } = useIntGetEventById(
    eventId!,
    false,
    eventId != "new"
  );

  const updateMutation = useIntUpdateEvent(queryClient);

  const createMutation = useCreateEventInt(queryClient, navigate);

  const deleteMutation = useDeleteEventInt(queryClient, eventId, navigate);

  const generateReq = (data: any): CreateEventIntReq => {
    const {
      title,
      location,
      maxReg,
      regNeeded,
      price,
      active,
      triggerNewEventNotification,
      autoAcceptRegistration,
      commentEnabled,
    } = data;

    let fromDate = moment(data.fromDate).toISOString();
    let toDate = moment(data.toDate).toISOString();
    let registrationDeadline = moment(data.regDeadline).toISOString();

    const description = convertDraftToHtml(descriptionEditorState);
    const acceptMailText = convertDraftToHtml(emailEditorState);

    return {
      title: title || "Neue Veranstaltung",
      description: description || "Beschreibung",
      location,
      price,
      fromDate,
      toDate,
      registrationDeadline,
      active: active || false,
      triggerNewEventNotification: triggerNewEventNotification || false,
      autoAcceptRegistration: autoAcceptRegistration || false,
      acceptMailText,
      commentEnabled,
      registrationNeeded: regNeeded || false,
      maxParticipants: Number(maxReg),
    };
  };

  const setForm = (event: EventIntRsp) => {
    setValue("title", event.title);
    setValue("description", event.description);
    setValue("location", event.location);
    setValue("price", event.price);
    setValue("active", event.active);
    setValue("triggerNewEventNotification", event.triggerNewEventNotification);
    setValue("autoAcceptRegistration", event.autoAcceptRegistration);
    setValue(
      "fromDate",
      event.fromDate
        ? moment(event.fromDate).toISOString(true).substring(0, 16)
        : ""
    );
    setValue(
      "toDate",
      event.toDate
        ? moment(event.toDate).toISOString(true).substring(0, 16)
        : ""
    );
    setValue(
      "regDeadline",
      event.registrationDeadline
        ? moment(event.registrationDeadline).toISOString(true).substring(0, 16)
        : ""
    );
    setValue("regNeeded", event.registrationNeeded);
    setValue("commentEnabled", event.commentEnabled);
    setValue("maxReg", event.maxParticipants);

    setDescriptionEditorState(convertHtmlToDraft(event.description));
    setEmailEditorState(convertHtmlToDraft(event.acceptMailText));
  };

  useEffect(() => {
    //Updates Form each time data was refetched
    if (data?.data != null) {
      setForm(data.data);
    }
    if (eventId === "new") {
      setForm({});
    }
  }, [data, eventId]);

  // determines the visibility of some fields
  const regEnabled = watch("regNeeded");

  return (
    <>
      {isError ? (
        <>Ein Fehler ist aufgetreten</>
      ) : (
        <StyledForm
          onSubmit={handleSubmit((data) => {
            alertSave(
              eventId && eventId !== "new"
                ? updateMutation.mutateAsync({
                    eventId,
                    req: generateReq(data),
                  })
                : createMutation.mutateAsync(generateReq(data))
            );
          })}
        >
          <label>Title:</label>
          <StyledInput {...register("title", { required: true })} />
          <label>Ort:</label>
          <StyledInput {...register("location", { required: true })} />
          <label>Preis:</label>
          <StyledInput
            type="number"
            {...register("price", { required: false })}
          />
          <label>Start:</label>
          <StyledInput
            type="datetime-local"
            {...register("fromDate", { required: true })}
          />
          <label>Ende:</label>
          <StyledInput
            type="datetime-local"
            {...register("toDate", { required: false })}
          />

          <div className="spacer" />
          <label>Beschreibung:</label>
          <div className="editor">
            <Editor
              editorState={descriptionEditorState}
              onEditorStateChange={setDescriptionEditorState}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "list",
                  "textAlign",
                  "history",
                ],
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: false },
              }}
            />
          </div>

          <label>Mailtext:</label>
          <div className="editor">
            <Editor
              editorState={emailEditorState}
              onEditorStateChange={setEmailEditorState}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "list",
                  "textAlign",
                  "history",
                ],
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: false },
              }}
            />
          </div>

          <div className="spacer" />

          <label>Anmeldung nötig:</label>
          <StyledInput
            type="checkbox"
            className="checkbox"
            style={{ height: "1.5rem", width: "1.5rem" }}
            {...register("regNeeded", { required: false })}
          />
          <label>Maximale Anmeldungen:</label>
          <StyledInput
            disabled={!regEnabled}
            type="number"
            {...register("maxReg", { required: false })}
          />
          <label>Anmelde Deadline:</label>
          <StyledInput
            disabled={!regEnabled}
            type="datetime-local"
            {...register("regDeadline", { required: false })}
          />
          <label>Anmeldungen automatisch akzeptieren:</label>
          <StyledInput
            disabled={!regEnabled}
            type="checkbox"
            className="checkbox"
            style={{ height: "1.5rem", width: "1.5rem" }}
            {...register("autoAcceptRegistration", { required: false })}
          />
          <label>Kommentare erlauben:</label>
          <StyledInput
            disabled={!regEnabled}
            type="checkbox"
            className="checkbox"
            style={{ height: "1.5rem", width: "1.5rem" }}
            {...register("commentEnabled", { required: false })}
          />
          <div className="spacer" />
          <label>
            Benachrichtigung auslösen (Nur bei öffentlichen Veranstaltungen):
          </label>
          <StyledInput
            type="checkbox"
            className="checkbox"
            style={{ height: "1.5rem", width: "1.5rem" }}
            {...register("triggerNewEventNotification", { required: false })}
          />
          <label>Öffentlich:</label>
          <StyledInput
            type="checkbox"
            className="checkbox"
            style={{ height: "1.5rem", width: "1.5rem" }}
            {...register("active", { required: false })}
          />

          <StyledButton type="submit"> Speichern</StyledButton>
          <StyledButton
            style={{ display: eventId === "new" ? "none" : "" }}
            type="button"
            onClick={() => {
              alertDelete(deleteMutation.mutateAsync());
            }}
          >
            {" "}
            Löschen
          </StyledButton>
        </StyledForm>
      )}
    </>
  );
}

export default EditEventDetailsTab;
