import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../components/ui/layout/header";
import Footer from "../../components/ui/layout/footer";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../../components/ui/styled-inputs";
import { RegisterAccountReq } from "../../components/api/generated";
import { useRegister } from "../../components/api/querys/auth-querys";
import { ProfileFormComponent } from "../../components/domain/user/profile-form";
import { useForm } from "react-hook-form";
import { alertError } from "../../components/ui/alert";

const RegisterPage = styled.main`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-areas: "header" "content" "footer";
  grid-template-rows: 100px auto 50px;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background: ${(props) => props.theme.secondaryLightest};
    gap: 4rem;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
`;

function RegisterRoute() {
  const [registered, setRegistered] = useState(false);
  const registerMutation = useRegister();

  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm();

  const onSubmit = (data: any) => {
    if (data.password !== data.passwordRepeat) {
      alertError(`Passwörter stimmen nicht überein`);
    } else {
      const user: RegisterAccountReq = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        ljvNumber: data.ljvNumber,
        phoneNumber: data.phoneNumber,
        street: data.street,
        houseNumber: data.houseNumber,
        city: data.city,
        postalCode: data.postalCode,
        password: data.password,
      };
      registerMutation
        .mutateAsync(user)
        .then(() => setRegistered(true))
        .catch(() => alertError(`Etwas hat nicht funktioniert`));
    }
  };

  return (
    <Wrapper>
      <RegisterPage>
        <Header />
        {!registered ? (
          <>
            <ProfileFormComponent
              register={register}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              submitButtonText="Registrierung abschließen"
              type="REGISTER"
              formState={formState}
              textSpace={
                <>
                  <h1>Registriere dich für das KJS Revier</h1>
                  <br />
                  Hier kannst du dich für das KJS Revier registrieren. Nach der
                  Registrierung musst du noch deine Email bestätigen und wirst
                  dann von einem unseren Administratoren freigeschaltet.
                  <br />
                  <br />
                  Wir Freuen uns auf dich... Waidmansheil
                </>
              }
              buttonSpace={
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/login");
                  }}
                >
                  Du hast schon einen Account? Klick hier!
                </StyledButton>
              }
            />
            <StyledButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/login");
              }}
            >
              Du hast schon einen Account? Klick hier!
            </StyledButton>
          </>
        ) : (
          <form>
            <h1>Registrierung erfolgreich!</h1>
            Überprüfe deine Emails und klicke zur Bestätigung auf den Link. Nach
            der Bestätigung wirst du dann von einem unserer Administratoren
            freigeschaltet.
          </form>
        )}
        <Footer />
      </RegisterPage>
    </Wrapper>
  );
}

export default RegisterRoute;
