import React from "react";
import styled from "styled-components";
import { EventRegistrationIntRsp } from "../../../api/generated";
import { BaseType } from "../../../../types/base-type";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import { StyledButton } from "../../../ui/styled-inputs";
import { useQueryClient } from "@tanstack/react-query";
import { alertSave } from "../../../ui/alert";
import { useUpdateEventRegistrationInt } from "../../../api/querys/event-registration-querys";

interface ManageRegistrationsListItemComponentProps extends BaseType {
  registration?: EventRegistrationIntRsp;
}

function ManageRegistrationsListItemComponentStruct(
  props: ManageRegistrationsListItemComponentProps
) {
  const { className, registration } = props;

  const queryClient = useQueryClient();

  const updateMutation = useUpdateEventRegistrationInt(queryClient);

  return (
    <div className={className}>
      <div>
        {registration ? registration.eventTitle : <h4>Event Titel</h4>}{" "}
      </div>
      <div>
        {registration ? (
          registration.participant?.firstName +
          " " +
          registration.participant?.lastName
        ) : (
          <h4>Nutzer Name</h4>
        )}{" "}
      </div>
      <div>
        {registration ? registration.participant?.email : <h4>Email</h4>}{" "}
      </div>
      <div>
        {registration ? (
          <StyledButton
            className="acceptButton"
            onClick={() => {
              alertSave(
                updateMutation.mutateAsync({
                  registrationId: registration?.id,
                  eventId: registration?.eventId,
                  req: { status: "ACCEPTED" },
                })
              );
            }}
          >
            <BsCheckCircle color="green" />
          </StyledButton>
        ) : (
          <h4>Akzeptieren</h4>
        )}{" "}
      </div>
      <div>
        {registration ? (
          <StyledButton
            className="rejectButton"
            onClick={() => {
              alertSave(
                updateMutation.mutateAsync({
                  registrationId: registration?.id,
                  eventId: registration?.eventId,
                  req: { status: "REJECTED" },
                })
              );
            }}
          >
            <BsXCircle color="red" />
          </StyledButton>
        ) : (
          <h4>Ablehnen</h4>
        )}{" "}
      </div>
    </div>
  );
}

const ManageRegistrationsListItemComponent = styled(
  ManageRegistrationsListItemComponentStruct
)`
  background-color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  justify-content: space-between;
  min-height: 2rem;
  width: 100%;
  border-radius: ${(props) => props.theme.mediumBorderRadius};

  h4 {
    margin: 0;
  }

  div:nth-child(1) {
    width: 12rem;
  }
  div:nth-child(2) {
    width: 10rem;
  }
  div:nth-child(3) {
    width: 10rem;
  }
  div:nth-child(4) {
    width: 6rem;
    justify-content: center;
  }
  div:nth-child(5) {
    width: 6rem;
    display: flex;
    justify-content: center;
  }

  .acceptButton,
  .rejectButton {
    width: 100%;
  }
`;

export default ManageRegistrationsListItemComponent;
