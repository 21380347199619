import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { CreateEventIntReq, EventIntRsp, PageReq } from "../generated";
import { eventController, eventIntController } from "../api-functions";
import { NavigateFunction } from "react-router-dom";

export const useIntUpdateEvent = (queryClient: QueryClient) =>
  useMutation({
    mutationFn: async ({
      eventId,
      req,
    }: {
      eventId: string;
      req: EventIntRsp;
    }) => {
      return await eventIntController.update1(eventId, req);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["events"]);
    },
  });

export const useIntDuplicateEvent = (
  queryClient: QueryClient,
  navigate: NavigateFunction
) =>
  useMutation({
    mutationFn: async ({ eventId }: { eventId: string }) => {
      let { data } = await eventIntController.getById1(eventId);
      let req: CreateEventIntReq = {
        title: "",
        commentEnabled: data.commentEnabled || false,
        description: data.description || "",
        fromDate: data.fromDate || "",
        ...data,
      };
      req.title = (data.title || "") + " Kopie";
      let postRes = await eventIntController.create1(req);
      navigate("/manage-events/" + postRes.data.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["events"]);
    },
  });

export const useCreateEventInt = (
  queryClient: QueryClient,
  navigate: NavigateFunction
) =>
  useMutation({
    mutationFn: async (data: CreateEventIntReq) => {
      return await eventIntController.create1(data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["events"]);
      navigate("/manage-events/" + data.data.id);
    },
  });

export const useDeleteEventInt = (
  queryClient: QueryClient,
  eventId: string | undefined,
  navigate: NavigateFunction
) =>
  useMutation({
    mutationFn: async () => {
      return await eventIntController.delete1(eventId!);
    },
    onSuccess: async () => {
      await queryClient.removeQueries(["events", eventId]);
      queryClient.invalidateQueries(["events"]);
      navigate("/manage-events");
    },
  });

export const useGetEventById = (eventId: string) =>
  useQuery({
    queryKey: ["events", eventId],
    queryFn: async () => {
      return await eventController.getById2(eventId);
    },
  });

export const useGetEvents = (pageData: PageReq, filterPastEvents?: boolean) =>
  useQuery({
    queryKey: ["events", pageData.search, pageData.page, filterPastEvents],
    queryFn: async () => {
      return await eventIntController.getAll1(pageData, filterPastEvents);
    },
  });

export const useIntGetEventById = (
  eventId?: string,
  refetchOnWindowFocus: boolean = true,
  enabled: boolean = true
) =>
  useQuery({
    queryKey: ["events", eventId],
    queryFn: async () => {
      return eventId ? await eventIntController.getById1(eventId) : null;
    },
    enabled,
    refetchOnWindowFocus,
  });

export const useEndlessRegisteredEvents = (
  searchText: string,
  filterPastEvents?: boolean
) =>
  useInfiniteQuery({
    queryKey: ["events", "registered", searchText, filterPastEvents],
    queryFn: async ({ pageParam = 0 }) =>
      await eventController.getRegistered(
        {
          page: pageParam,
          pageSize: 10,
          search: searchText,
        },
        filterPastEvents
      ),
    getNextPageParam: (lastPage) => {
      const last = lastPage.data.number;
      const total = lastPage.data.totalPages;
      return last != null && total != null && last < total - 1
        ? last + 1
        : undefined;
    },
  });
export const useEndlessEvents = (
  searchText: string,
  filterPastEvents?: boolean
) =>
  useInfiniteQuery({
    queryKey: ["events", searchText, filterPastEvents],
    queryFn: async ({ pageParam = 0 }) =>
      await eventController.getAll2(
        {
          page: pageParam,
          pageSize: 10,
          search: searchText,
        },
        filterPastEvents
      ),
    getNextPageParam: (lastPage) => {
      const last = lastPage.data.number;
      const total = lastPage.data.totalPages;
      return last != null && total != null && last < total - 1
        ? last + 1
        : undefined;
    },
  });
