import styled from "styled-components";
import React from "react";

export const Card = styled.article`
  background-color: white;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-radius: ${(props) => props.theme.bigBorderRadius};
  box-shadow: ${(props) => props.theme.boxShadow};

  .closeButton {
    width: 2rem;
    height: 2rem;
    align-self: end;
    border: none;
    background: none;
    cursor: pointer;
  }
`;
