import React from "react";
import styled from "styled-components";
import Header from "../../components/ui/layout/header";
import Footer from "../../components/ui/layout/footer";
import { LoginForm } from "../../components/domain/user/login-form";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
`;

const LoginPage = styled.main`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-areas: "header" "image" "text" "footer";
  grid-template-rows: 100px 50% auto 50px;

  @media only screen and (max-width: 1000px) {
    grid-template-rows: 100px 50% auto 50px;
  }

  @media only screen and (max-width: 500px) {
    grid-template-rows: 100px 30% auto 50px;
  }

  .details {
    grid-area: text;
    flex-direction: row;
    justify-content: center;
    display: flex;
    flex-wrap: wrap-reverse;
    height: 100%;
    width: 100%;
    background-color: #dfe9e3;
  }

  .imgContainer {
    grid-area: image;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url("/landingPagePicture.jpeg");
    background-size: cover;
    background-position: center bottom;
    position: relative;
    text-align: center;
  }

  .imgContainer img {
    width: 100%;
    margin: -300px 0 0 0;
  }

  .imgText {
    position: absolute;
    top: 20%;
    left: 20%;
    color: white;
    font-size: 4em;
    text-align: left;

    @media only screen and (max-width: 500px) {
      font-size: 3em;
      top: 20%;
      left: 10%;
    }
  }

  a {
    text-decoration: none;
    color: #387c49;
  }
`;

const TextSpace = styled.section`
  width: 40%;
  padding: 4rem 1rem;

  h2 {
    padding: 0;
  }

  @media only screen and (max-width: 900px) {
    width: auto;
    padding: 0 1rem 2rem 1rem;
  }
`;

export default function LoginRoute() {
  return (
    <Wrapper>
      <LoginPage>
        <Header />
        <div className="imgContainer">
          <div className="imgText">
            Plattform der <br />
            Kreisjägerschaft Aachen
          </div>
        </div>
        <div className="details">
          <TextSpace>
            <h2>Willkommen auf der Veranstaltungsplattform KJS-Revier</h2>
            Herzlich willkommen! Hier im KJS-Revier finden Sie alle anstehenden
            Veranstaltungen und Fortbildungen der KJS Aachen, zu denen eine
            Anmeldung erforderlich ist. Wenn Sie bereits registriert sind,
            können Sie sich einloggen und sich zu den Veranstaltungen anmelden.
            Sollten Sie noch nicht registriert sein, melden Sie sich bitte an.
            Hinweis: Es können sich ausschließlich Mitglieder der KJS Aachen und
            Mitglieder des LJV NRW anmelden.
          </TextSpace>
          <LoginForm />
        </div>
        <Footer />
      </LoginPage>
    </Wrapper>
  );
}
