import styled from "styled-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EventRsp } from "../../api/generated";
import { StyledButton } from "../../ui/styled-inputs";
import { BaseType } from "../../../types/base-type";
import { EventRegistrationStatus } from "./event-registration-status";
import moment from "moment";
import HTMLTrimmer from "../../ui/html-trimmer";

interface EventCardProps extends BaseType {
  event: EventRsp;
}

const EventCardStruct = (props: EventCardProps) => {
  const {
    id,
    title,
    description,
    fromDate,
    toDate,
    location,
    registrationStatus,
    payed,
  } = props.event;

  const navigate = useNavigate();

  return (
    <article className={props.className}>
      <div className="eventCardHeadline">
        <h2>{title}</h2>
        <EventRegistrationStatus
          registrationStatus={registrationStatus}
          payed={payed}
        />
      </div>
      <section className="details">
        <h4>
          {fromDate
            ? "von " +
              moment(fromDate).toDate().toLocaleDateString() +
              " " +
              new Date(fromDate).toLocaleTimeString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
              })
            : "Noch kein Datum festgelegt"}
        </h4>
        {toDate != null
          ? "bis " +
            new Date(toDate).toLocaleDateString() +
            " " +
            new Date(toDate).toLocaleTimeString("de-DE", {
              hour: "2-digit",
              minute: "2-digit",
            })
          : ""}
        <br />
        {location}
        <HTMLTrimmer description={description || ""} className="description" />
      </section>

      <div className="buttonSpace">
        <StyledButton onClick={() => navigate("/events/" + id)}>
          Details
        </StyledButton>
      </div>
    </article>
  );
};

export const EventCard = styled(EventCardStruct)`
  height: 25rem;
  padding: 1rem;
  background: white;

  display: grid;
  grid-template-areas: "header" "details" "description" "button";
  grid-template-rows: 6rem 4rem 8rem 2rem;
  grid-row-gap: 1rem;

  border-radius: ${(props) => props.theme.bigBorderRadius};

  box-shadow: ${(props) => props.theme.boxShadow};

  .eventCardHeadline {
    grid-area: header;
    width: 100%;
    padding-bottom: 0.5rem;

    border-bottom: 1px solid ${(props) => props.theme.secondaryDark};

    h2 {
      margin-top: 0;
    }
  }

  .details {
    grid-area: details;
    width: 100%;
    max-height: 10rem;

    h4 {
      margin: 0;
    }
  }

  .description {
    max-height: 10rem;
    padding-bottom: 1rem;
  }

  .buttonSpace {
    grid-area: button;
    display: flex;
    padding: 0;
  }

  button {
    align-self: flex-end;
    padding: 8px 20px 8px 20px;
  }
`;
