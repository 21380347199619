import React from "react";

import styled from "styled-components";
import {
  AdminRoutes,
  MemberRoutes,
  RouteProps,
} from "../../../routes/route-config";
import { Link } from "react-router-dom";
import { StyledButton } from "../styled-inputs";
import { useQueryClient } from "@tanstack/react-query";
import { useCurrentPath } from "../../../utils/get-current-path";
import { useGetMe, useLogout } from "../../api/querys/auth-querys";
import { BsArrowRightSquare } from "react-icons/bs";

const StyledNav = styled.nav`
  background-image: linear-gradient(#0a7f44, #ddeae3 80%);
  grid-area: nav;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 0;
  line-height: 2;
  z-index: 2;

  padding: 2rem 2rem;

  .mobileProfile {
    border: none;
    background: none;
    font-weight: bold;
    font-size: 1.3rem;
    color: #f5f5f5;
    flex: none;
    padding: 0.25rem;
    display: none;
  }

  .desktopProfile {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }

  a,
  .top-react-icons,
  .desktopProfile {
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3rem;
    color: #f5f5f5;
  }

  a,
  .desktopProfile {
    max-width: 90%;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: normal;
    gap: 0.5rem;
  }

  a div,
  .desktopProfile div {
    padding-left: 0.3rem;
  }

  .top-react-icons {
    flex: none;
  }

  .desktopProfile {
    margin-bottom: 2rem;
  }

  .adminRoutes,
  .memberRoutes {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .adminRoutes {
    border-bottom: 1px #f5f5f5 solid;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
  }

  a:hover,
  a:focus,
  a:hover .top-react-icons,
  a:focus .top-react-icons,
  .desktopProfile:hover,
  .desktopProfile:hover .top-react-icons,
  .selected,
  .selected .top-react-icons {
    color: #f0dfc9;
  }
`;

const Nav = () => {
  const queryClient = useQueryClient();
  const logout = useLogout(queryClient);
  let currentPath = useCurrentPath();
  const { data } = useGetMe();

  return (
    <StyledNav>
      {data?.role === "ADMIN" && (
        <div className="adminRoutes">
          {RenderNavbarLinks(AdminRoutes, currentPath)}
        </div>
      )}
      <div className="memberRoutes">
        {RenderNavbarLinks(MemberRoutes, currentPath)}
        <button className="mobileProfile" onClick={() => logout.mutateAsync()}>
          <BsArrowRightSquare />
        </button>
      </div>

      <StyledButton
        onClick={async (e) => {
          e.preventDefault();
          logout.mutate();
        }}
        className="desktopLogout"
      >
        {" "}
        Logout
      </StyledButton>
    </StyledNav>
  );
};
export default Nav;

export function RenderNavbarLinks(routes: RouteProps[], currentRoute: string) {
  return routes.map((route, index) => {
    return NavbarLink(route, index, currentRoute);
  });
}

function NavbarLink(route: RouteProps, key: number, currentPath: string) {
  return (
    <Link
      className={currentPath === route.path ? "selected" : ""}
      key={key}
      to={route.path}
    >
      <>
        {" "}
        {<route.icon size="1.3rem" />}
        <div>{route.name}</div>
      </>
    </Link>
  );
}
