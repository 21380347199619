import {
  AccountControllerApi,
  AccountIntControllerApi,
  AddEventAssetReq,
  AddEventAssetReqAssetTypeEnum,
  AssetControllerApi,
  AssetIntControllerApi,
  AssetRsp,
  Configuration,
  EventAssetIntControllerApi,
  EventControllerApi,
  EventIntControllerApi,
  NotificationControllerApi,
  RegisterControllerApi,
} from "./generated";
import fileDownload from "js-file-download"; //set header against basic auth pop up

//set header against basic auth pop up
const openapiConfig = new Configuration();
openapiConfig.baseOptions = {
  headers: {
    "x-requested-with": "XMLHttpRequest",
  },
};

export const accountController = new AccountControllerApi(openapiConfig);
export const accountIntController = new AccountIntControllerApi(openapiConfig);
export const eventController = new EventControllerApi(openapiConfig);
export const eventIntController = new EventIntControllerApi(openapiConfig);
export const registerController = new RegisterControllerApi(openapiConfig);
export const assetController = new AssetControllerApi(openapiConfig);
export const assetIntController = new AssetIntControllerApi(openapiConfig);

export const eventAssetIntController = new EventAssetIntControllerApi(
  openapiConfig
);

export const notificationController = new NotificationControllerApi(
  openapiConfig
);

export const addFilesToEvent = async (
  files: FileList,
  eventId: string,
  assetType?: AddEventAssetReqAssetTypeEnum
) => {
  let assets: AddEventAssetReq[] = [];
  await Promise.all(
    Array.from(files).map(async (file) => {
      let res = await assetIntController.upload(file);
      assets.push({
        assetId: res.data.id,
        assetName: res.data.fileName,
        assetType,
      });
    })
  );
  return await eventAssetIntController.addAsset(eventId, {
    assets,
  });
};

export const downloadAsset = async (asset: AssetRsp) => {
  assetController.download(asset.id!, { responseType: "blob" }).then((res) => {
    fileDownload(res.data as unknown as Blob, asset.fileName || "unnamed.png");
  });
};
