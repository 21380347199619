import React, { useState } from "react";
import { PageWrapper } from "../../components/ui/page-wrapper";
import { Outlet } from "react-router-dom";
import { BreadCrumbsProps } from "../../components/ui/layout/bread-crumbs";
import { BaseType } from "../../types/base-type";
import { LoadingSpinner } from "../../components/ui/loading-spinner";
import ManageRegistrationsListItemComponent from "../../components/domain/user/admin/manage-registrations-list-item-component";
import { ListWrapper } from "../../components/ui/list-wrapper";
import { useGetPendingRegistrationsInt } from "../../components/api/querys/event-registration-querys";

function ManageRegistrationsRoute(props: BaseType) {
  const { children } = props;
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  const { data, isLoading, isError } = useGetPendingRegistrationsInt(
    page,
    searchText
  );

  const navPathLinks: BreadCrumbsProps[] = [{ text: "Offene Anmeldungen" }];

  return (
    <PageWrapper
      links={navPathLinks}
      searchText={searchText}
      setSearchText={setSearchText}
      pageTitle="Offene Anmeldungen"
      pagination={{
        page,
        setPage,
        totalPages: data?.data.totalPages,
        totalElements: data?.data.totalElements,
      }}
    >
      <ListWrapper>
        <ManageRegistrationsListItemComponent />
        {isError && "Ein Fehler ist aufgetreten.."}
        {isLoading && <LoadingSpinner />}
        {data?.data?.elements?.map((registration, i) => (
          <ManageRegistrationsListItemComponent
            key={i}
            registration={registration}
          />
        ))}
      </ListWrapper>
      {children ? children : <Outlet />}
    </PageWrapper>
  );
}

export default ManageRegistrationsRoute;
