import styled from "styled-components";
import React from "react";
import { BreadCrumbs, BreadCrumbsProps } from "./layout/bread-crumbs";
import { BaseType } from "../../types/base-type";
import { StyledButton, StyledInput } from "./styled-inputs";
import { BsPlusCircle } from "react-icons/bs";
import Paginierung, { PaginierungProps } from "./paginierung";
import { useNavigate } from "react-router-dom";

interface WrapperProps extends BaseType {
  links?: BreadCrumbsProps[];
  pageTitle?: string;
  searchText?: string;
  setSearchText?: (text: string) => any;
  pagination?: PaginierungProps;
  hasCreateButton?: boolean;
  additionalInputs?: React.ReactNode;
}

function Wrapper(props: WrapperProps) {
  const {
    children,
    links,
    pageTitle,
    className,
    searchText,
    setSearchText,
    pagination,
    hasCreateButton,
    additionalInputs,
  } = props;

  const navigate = useNavigate();

  return (
    <section className={className}>
      <div className="top-section">
        <BreadCrumbs links={links} />
        <div className="top-top-section">
          <h1>{pageTitle}</h1>
          {hasCreateButton && (
            <StyledButton
              onClick={() => {
                navigate("new");
              }}
              style={{ gap: ".5rem" }}
            >
              <BsPlusCircle />
              Neu Anlegen
            </StyledButton>
          )}
        </div>
        <div className="input-section">
          {additionalInputs && additionalInputs}
          {setSearchText && (
            <StyledInput
              placeholder="&#x1F50E; Stichwortsuche"
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
        </div>
      </div>
      {children}
      <div className="bottom-section">
        {pagination && (
          <Paginierung
            page={pagination.page}
            setPage={pagination.setPage}
            totalPages={pagination.totalPages}
            totalElements={pagination.totalElements}
          />
        )}
      </div>
    </section>
  );
}

export const PageWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  min-height: 100%;
  max-width: 100rem;
  width: 100%;

  .top-section {
    border-bottom: 1px solid ${(props) => props.theme.secondaryDark};
    display: flex;
    flex-direction: column;
    padding-bottom: 0.75rem;
    margin-bottom: 1.5rem;

    h1 {
      margin: 0;
    }
  }

  .input-section {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    @media only screen and (max-width: 850px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .input-section > :last-child {
    flex-grow: 1;
  }

  .top-top-section {
    display: flex;
    justify-content: space-between;
  }

  .bottom-section {
    margin-top: 1rem;
  }
`;
