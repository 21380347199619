import { useParams } from "react-router-dom";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { EventDetailsComponent } from "../../components/domain/event/event-details-component";
import { PageWrapper } from "../../components/ui/page-wrapper";
import { Card } from "../../components/ui/card";
import { useQueryClient } from "@tanstack/react-query";
import { BreadCrumbsProps } from "../../components/ui/layout/bread-crumbs";
import { StyledButton, StyledInput } from "../../components/ui/styled-inputs";
import fileDownload from "js-file-download";
import { createIcalFromEvent } from "../../utils/create-ical-from-event";
import { LoadingSpinner } from "../../components/ui/loading-spinner";
import { ImageCarousel } from "../../components/ui/image-carousel";
import {
  useRegisterForEvent,
  useUnregisterForEvent,
} from "../../components/api/querys/event-registration-querys";
import { useGetEventById } from "../../components/api/querys/event-querys";
import { EventFileListItemComponent } from "../../components/domain/event/admin/event-file-list-item-component";
import { alertError } from "../../components/ui/alert";

const EventName = styled.h1`
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

const Description = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;

  h4 {
    align-self: flex-start;
    margin: 0;
  }

  .textSpace {
  }

  .buttonSpace {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
  }

  .commentSpace {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.25rem;

    input {
      width: 100%;
    }
  }

  .assetSpace {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .assetList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 1rem;
  }
`;

const Button = styled(StyledButton)`
  width: 100%;
  height: 3em;
  min-width: 150px;
`;

const Grid = styled.section`
  display: grid;
  grid-template-columns: 50% auto;
  padding-top: 1.5rem;
  grid-column-gap: 1rem;

  @media only screen and (max-width: 600px) {
    grid-template-columns: unset;
    grid-template-rows: auto auto;
  }
`;

export default function EventDetailsRoute() {
  const { id } = useParams();

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useGetEventById(id!);

  const register = useRegisterForEvent(queryClient);
  const unregister = useUnregisterForEvent(queryClient);

  const [comment, setComment] = useState("");

  const navPathLinks: BreadCrumbsProps[] = [
    {
      text: "Anstehende Veranstaltungen",
      link: "/events",
    },
    { text: data?.data.title ? data.data.title : "Unbekanntes Event" },
  ];

  useEffect(() => {
    if (comment == null || comment.trim() === "")
      setComment(data?.data.comment || "");
  }, [data]);

  if (isLoading) {
    return (
      <PageWrapper links={navPathLinks}>
        <LoadingSpinner />
      </PageWrapper>
    );
  }
  if (isError) {
    return (
      <PageWrapper links={navPathLinks}>
        Ein fehler ist aufgetreten. Vielleicht existiert das Event nicht mehr?
      </PageWrapper>
    );
  }

  if (id == null) {
    return (
      <PageWrapper links={navPathLinks}>
        <div>Das Event konnte nicht gefunden werden.</div>
      </PageWrapper>
    );
  }

  let inRegistrationWindow =
    data?.data.registrationDeadline != null &&
    new Date(data.data.registrationDeadline) > new Date();
  return (
    <PageWrapper pageTitle={data?.data.title} links={navPathLinks}>
      <Card>
        <EventName>{data?.data.title}</EventName>
        <EventDetailsComponent event={data.data} />
        <Grid>
          <ImageCarousel
            images={data?.data.assets?.filter((asset) => {
              return asset.eventAssetType === "MAIN_PICTURE";
            })}
          />
          <Description>
            <div>
              <h4>Über die Veranstaltung:</h4>
              <div>
                <section
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: data?.data.description || "",
                  }}
                />
              </div>
            </div>

            {data?.data.commentEnabled &&
              (data.data.registrationStatus || inRegistrationWindow) && (
                <div className="commentSpace">
                  <h4>Kommentar zur Anmeldung:</h4>
                  <StyledInput
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    disabled={data.data.registrationStatus != null}
                  />
                </div>
              )}
            <div className="buttonSpace">
              {inRegistrationWindow &&
                (data.data && data.data.registrationStatus ? (
                  data.data.registrationStatus != "REJECTED" ? (
                    <Button onClick={() => unregister.mutate(id)}>
                      Abmelden
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <Button onClick={() => register.mutate({ id, comment })}>
                    Anmelden
                  </Button>
                ))}
              <Button
                onClick={() => {
                  const { value, error } = createIcalFromEvent(data?.data);
                  if (error || !value) {
                    alertError("Etwas hat nicht funktioniert");
                    return;
                  }
                  fileDownload(value, data?.data.title + ".ics");
                }}
              >
                Zum Kalender hinzufügen
              </Button>
            </div>
            {data?.data?.assets != null &&
              data.data.assets.filter((s) => s.eventAssetType === "MATERIAL")
                .length > 0 && (
                <div className="assetSpace">
                  <h4>Anhänge: </h4>
                  <div className="assetList">
                    {data?.data.assets
                      ?.filter((asset) => {
                        return asset.eventAssetType === "MATERIAL";
                      })
                      .map((asset) => {
                        return (
                          <EventFileListItemComponent
                            key={asset.id}
                            asset={asset}
                            eventId={id}
                            isAdmin={false}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
          </Description>
        </Grid>
      </Card>
    </PageWrapper>
  );
}
