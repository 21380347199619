import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./components/ui/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RevierRouter } from "./routes/route-config";
import { Toaster } from "react-hot-toast";
import { IconContext } from "react-icons";

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <IconContext.Provider
          value={{ className: "top-react-icons", size: "1rem" }}
        >
          <Toaster position={"bottom-center"} />
          <RevierRouter />
        </IconContext.Provider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
