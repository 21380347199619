import React from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  grid-area: footer;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  a:before {
    content: "|";
    color: black;
    margin: 0.25em 0.25em;
  }

  a {
    text-decoration: none;
    color: #387c49;
  }

  a,
  span,
  a::content {
    height: 1rem;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <span>© Kreisjägerschaft Aachen Stadt und Land e.V. 2023</span>
      <Link to="/impressum">Impressum</Link>
    </StyledFooter>
  );
};
export default Footer;
